import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Articles_1 from "../../Img/house_planet.jpg"
import Articles_2 from "../../Img/house_light.jpg"
import Articles_3 from "../../Img/house_style.jpg"
import { FaUserAlt } from "react-icons/fa"
import { BiSolidLockAlt } from "react-icons/bi"
import { AiFillEyeInvisible } from "react-icons/ai"
import { AiFillEye } from "react-icons/ai"
import { FaGoogle } from "react-icons/fa"
import { FaFacebookF } from "react-icons/fa"
import { BsTelephoneFill } from "react-icons/bs"
import { IoMdMail } from "react-icons/io"
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Signup() {

  const navigate = useNavigate()

  const [RegisterData, setRegisterData] = useState(

    {
      username: "",
      phone: "",
      email: "",
      Password: ""
    }

  )

  const handelchange = (e) => {
    setRegisterData({ ...RegisterData, [e.target.name]: e.target.value })

  }


  const handelsubmit = (e) => {
    e.preventDefault()
    if (RegisterData.username && RegisterData.phone && RegisterData.email && RegisterData.Password) {
      toast.success("Register successful")
      navigate("/")

    } else {
      toast.warning("Please fill all data ")
    }
  }

  
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div className='container-fluid signup-bg-div pading-div'>
        <div className='custom-container'>


          <div className='row'>

            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-xl-4 px-lg-0 login-padding-div '>
              <div className='loginpage-div'>


                <h1 className='login-p-headind pt-0'>Furniture Marketplace</h1>

                <div  >

                  <Carousel data-bs-theme="dark" >
                    <Carousel.Item >
                      <img
                        className="d-block  carousel-img login-carausel  "
                        src={Articles_3}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block  carousel-img login-carausel "
                        src={Articles_1}
                        alt="Second slide"
                      />

                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block  carousel-img login-carausel"
                        src={Articles_2}
                        alt="Third slide"
                      />

                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block  carousel-img  login-carausel"
                        src={Articles_1}
                        alt="Third slide"
                      />

                    </Carousel.Item>

                  </Carousel>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-xl-4 px-lg-0 login-padding-div '>

              <div className='loginpage-div'>

                <h1 className='login-p-headind  '>Register to Shop Bag</h1>
                <div className='login-input-div'>
                  <FaUserAlt className='user-icon' />
                  <input type="text" className='user-input' placeholder='Username' onChange={handelchange}  value={RegisterData.username} name='username'/>
                </div>
                <div className='login-input-div'>
                  <BsTelephoneFill className='user-icon' />
                  <input type="text" className='user-input' placeholder='Phone' onChange={handelchange} value={RegisterData.phone} name='phone'/>
                </div>
                <div className='login-input-div'>
                  <IoMdMail className='user-icon' />
                  <input type="text" className='user-input' placeholder='Email' onChange={handelchange} value={RegisterData.email} name='email'/>
                </div>


                <div className='login-input-div'>
                  <BiSolidLockAlt className='icon-lock' />
                  {visible ? <AiFillEye className="icon-eye"  onClick={() => setVisible(!visible)}/> : <AiFillEyeInvisible className="icon-eye" onClick={() => setVisible(!visible)} />   }
                  <input type={visible ? "text" : "password"} className='pass-input' placeholder='Password'onChange={handelchange} value={RegisterData.Password} name='Password'/>
                </div>

                <div>
                  <button className='loginp-button ' type='submit' onClick={handelsubmit}>Register</button>
                </div>
                <p className='account'>Already Have Account? <span className='register' onClick={() => navigate("/login")}>Login</span></p>

                <div className='social-auth-div'>
                  <button className='google-btn'><FaGoogle className='google-icon' />Google</button>
                  <button className='facebook-btn'><FaFacebookF className='facebook-icon' />Facebook</button>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>


    </>
  )
}

export default Signup