import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import "./Main.css"
import "./Responsive.css"




// const Header = React.lazy(() => import("./Global/Header/Header"))
// const Loader = React.lazy(() => import("./View/Loader/Loader"))
// const Home = React.lazy(() => import("./View/Home/Home"))
// const Article = React.lazy(() => import("./View/Article/Article"))
// const Product = React.lazy(() => import("./View/Product/Product"))
// const ShippingData = React.lazy(() => import("./View/ShippingData/ShippingData"))
// const Gallery = React.lazy(() => import("./View/Gallery/Gallery"))
// const ShoppingCart = React.lazy(() => import("./View/ShoppingCart/Shoppingcart"))
// const Payment = React.lazy(() => import("./View/Payment/Payment"))
// const Wishlist = React.lazy(() => import("./View/Wishlist/Wishlist"))
// const ProductDetail = React.lazy(() => import("./View/ProductDetail/ProductDetail"))
// const Login = React.lazy(() => import("./View/Login/Login"))
// const Signup = React.lazy(() => import("./View/Signup/Signup"))
// const Team = React.lazy(() => import("./View/Team/Team"))
// const ContactUs = React.lazy(() => import("./View/ContactUs/ContactUs"))
// const Testimonials = React.lazy(() => import("./View/Testimonials/Testimonials"))
// const Pricing = React.lazy(() => import("./View/Pricing/Pricing"))
// const Footer = React.lazy(() => import("./Global/Footer/Footer"))


import Header from "./Global/Header/Header"
import Loader from "./View/Loader/Loader"
import Home from "./View/Home/Home"
import Article from "./View/Article/Article"
import Product from "./View/Product/Product"
import ShippingData from "./View/ShippingData/ShippingData"
import Gallery from "./View/Gallery/Gallery"
import ShoppingCart from "./View/ShoppingCart/Shoppingcart"
import Payment from "./View/Payment/Payment"
import Wishlist from "./View/Wishlist/Wishlist"
import ProductDetail from "./View/ProductDetail/ProductDetail"
import Login from "./View/Login/Login"
import Signup from "./View/Signup/Signup"
import Team from "./View/Team/Team"
import ContactUs from "./View/ContactUs/ContactUs"
import Testimonials from "./View/Testimonials/Testimonials"
import Pricing from "./View/Pricing/Pricing"
import Footer from "./Global/Footer/Footer"

const App = () => {


  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])


  // Mode
  const [mode, setMode] = useState("light-mode")

  const togglemode = () => {
    mode === "light-mode" ? setMode("dark-mode") : setMode("light-mode")
  }

  useEffect(() => {
    document.body.className = mode;
  }, [mode])


  return (
    <>

      <Suspense fallback={<Loader />}>
        <Header mode={mode} togglemode={togglemode} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/article' element={<Article />} />
          <Route path='/product' element={<Product />} />
          <Route path='/shippingdata' element={<ShippingData />} />
          <Route path='/shoppingcart' element={<ShoppingCart />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/wishlist' element={<Wishlist />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/ProductDetail' element={<ProductDetail />} />
          <Route path='/team' element={<Team />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/pricing' element={<Pricing />} />
        </Routes>
        <Footer mode={mode} togglemode={togglemode} />



      </Suspense>


    </>
  )
}

export default App