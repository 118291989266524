import React from 'react'

import Minimarket from "../../Img/Minimarket_Pay.png"
import Minimarket1 from "../../Img/Minimarket_Pay_1.png"
import DigitalWallet from "../../Img/Digital_Wallet_Pay.png"
import DigitalWallet1 from "../../Img/Digital_Wallet_Pay__1.png"
import DigitalWallet2 from "../../Img/Digital_Wallet_Pay_2.png"
import Bank from "../../Img/Bank_Pay.png"
import Bank1 from "../../Img/bank_pay_1.png"
import Bank2 from "../../Img/Bank_Pay__2.png"
import InternationalPayment from "../../Img/International_Payment.png"
import { useNavigate } from 'react-router-dom'

function Payment() {
    const navigate =useNavigate()
    return (
        <>

            <div className='container-fluid mode-div-2 pading-div'>
                <div className='custom-container'>
                    <div className='container'>

                        <div className='Product-div py-4'>
                            <h1 className='mb-0'>Payment</h1>
                        </div>

                        <div className='row'>

                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                <p className='mb-0 paymentway-heading'>
                                    Minimarket
                                </p>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={Minimarket} alt="" className='payment-img' />
                                </div>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={Minimarket1} alt="" className='payment-img' />
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>

                                <p className='mb-0 paymentway-heading'>
                                    Digital Wallet
                                </p>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={DigitalWallet} alt="" className='payment-img rounded ' />
                                </div>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={DigitalWallet1} alt="" className='payment-img' />
                                </div>
                                <div className='payment-div link-aja-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={DigitalWallet2} alt="" className='payment-img Link-aja' />
                                </div>

                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                                <p className='mb-0 paymentway-heading'>
                                    Bank
                                </p>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={Bank} alt="" className='payment-img  ' />
                                </div>
                                <div className='payment-div mandiri-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={Bank1} alt="" className='payment-img' />
                                </div>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={Bank2} alt="" className='payment-img ' />
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'>
                            <p className='mb-0 paymentway-heading'>
                            International Payment
                                </p>
                                <div className='payment-div'>
                                    <input type="radio" name="payment" id="" />
                                    <img src={InternationalPayment} alt="" className='payment-img  ' />
                                </div>

                            </div>
                        </div>

                        <div className='btn-div py-xl-5 py-lg-5 py-md-5 py-3'>
                <button className='back-to-shopping-btn ' onClick={()=>navigate("/shippingdata")}>Back to Shipping Data</button>
                <button className='payment-btn' onClick={()=>navigate("/")}>Pay Now</button>
              </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment