import React from 'react'
import { AiFillStar } from "react-icons/ai"
import Card_img from "../../Img/Profile_3.png"
import GoogleMapReact from 'google-map-react';


function ContactUs() {

    const defaultProps = {
        center: {
            lat: 21.170240,
            lng: 72.831062
        },
        zoom: 11
    };
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    return (
        <>
            <div className='container-fluid mode-div-2 pading-div px-0'>
                 {/* AIzaSyBZ6Kv4YWs4aJdZs0iubXpa6_jiHuKiPIk */}
                 <div   className='map-div'>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyBZ6Kv4YWs4aJdZs0iubXpa6_jiHuKiPIk" }}
                                    defaultCenter={defaultProps.center}
                                    defaultZoom={defaultProps.zoom}
                                >
                                    <AnyReactComponent
                                        lat={21.170240}
                                        lng={72.831062}
                                        text={""}
                                    />
                                </GoogleMapReact>
                            </div>

                <div className='custom-container'>
                    <div className='container'>
                       

                    
                           

                       

                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-12'>
                                <div className='form-paddding'>
                                    <h1 className='contact-heading'> Love  to hear from you, Get in touch</h1>

                                    <p className='contact-discp'>Clarity gives you the blocks & components you need to create a truly professional website, landing page or admin panel for your SaaS and gives the blocks.  </p>
                                    <div className='conatct-card'>

                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />

                                        <p className='conatct-card-dis'>"We love Landingfolio! Our designers were using it for their project, so we already knew what kind of design they want." </p>


                                        <div className='d-flex align-items-center '>
                                            <div>
                                                <img src={Card_img} alt="" className='card-contact-img' />
                                            </div>
                                            <div>
                                                <p className='mb-0 contact-card-name' >Devon Lane</p>
                                                <p className='mb-0 conatct-card-company'>DLDesign.co</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12'>
                                <div className='form-paddding'>
                                    <div className='contact-form-card shadow-sm'>
                                        <h3 className='form-heading'> Send us a message</h3>

                                        <input type="text" className='from-input' placeholder='Your name' />
                                        <input type="text" className='from-input' placeholder='Email address' />
                                        <div className="contact-input-div">
                                            <select name="Us" id="" defaultValue={'US'} className=' form-option'>
                                                <option value="US"> US</option>
                                                <option value="IND">IN</option>
                                                <option value="CAN"> CA</option>
                                                <option value="NZ">NZ</option>
                                            </select>
                                            <input type="text" className="from-option-input" placeholder="+1-222-333-4444" />

                                        </div>

                                        <textarea name="" id="" rows="5" className='from-input' placeholder='Write Your message'></textarea>

                                        <button className='form-button'>Send message</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs
