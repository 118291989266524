import product1_1 from "../../Img/whiteiceChair.jpg"
import product2_2 from "../../Img/Black_chair_sofa.webp"
import product3_3 from "../../Img/black_chair.jpg"
import product4_4 from "../../Img/orange_chair.jpg"
import product5_5 from "../../Img/orange-armchair_950558-7442.jpg"
import product6_6 from "../../Img/whiteChairr.jpeg"
import product7_7 from "../../Img/black_chaire_2.webp"
import product8_8 from "../../Img/product__8.jpg"


import product01_01 from "../../Img/whitechair.jpg" 
import product02_02 from "../../Img/black_chair_2.jpg" 
import product03_03 from "../../Img/sofa_chair.jpg" 
import product04_04 from "../../Img/soft_dark_chair.jpg" 

export const product = [

    {
        id:1,
        img_url: product1_1,
        product_like_cName: "product-like-btn",
        product_titel: "White Ice Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$120",
        product_discount: "$200",
        product_percentage_discount: "-40%"
    },
    {
        id:2,
        img_url: product2_2,
        product_like_cName: "product-like-btn",
        product_titel: "Fullset Black Chair & Sofa",
        product_item_sell: "(360 Sell)",
        product_price: "$999",
        product_discount: "",
        product_percentage_discount: ""
    },
    {
        id:3,
        img_url: product3_3,
        product_like_cName: "product-like-btn",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$190",
        product_discount: "$200",
        product_percentage_discount: "-10%"
    },
    {
        id:4,
        img_url: product4_4,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    },
    {
        id:5,
        img_url: product5_5,
        product_like_cName: "product-like-btn",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$190",
        product_discount: "$200",
        product_percentage_discount: "-10%"
    },
    {
        id:6,
        img_url: product6_6,
        product_like_cName: "product-like-btn",
        product_titel: "White Ice Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$120",
        product_discount: "$200",
        product_percentage_discount: "-40%"
    },
    {
        id:7,
        img_url: product7_7,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    },

    {
        id:8,
        img_url: product8_8,
        product_like_cName: "product-like-btn",
        product_titel: "Fullset Black Chair & Sofa",
        product_item_sell: "(360 Sell)",
        product_price: "$999",
        product_discount: "",
        product_percentage_discount: ""
    },
]
export const product_2 = [

    {
        img_url: product1_1,
        product_like_cName: "product-like-btn",
        product_titel: "White Ice Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$120",
        product_discount: "$200",
        product_percentage_discount: "-40%"
    },
    {
        img_url: product2_2,
        product_like_cName: "product-like-btn",
        product_titel: "Fullset Black Chair & Sofa",
        product_item_sell: "(360 Sell)",
        product_price: "$999",
        product_discount: "",
        product_percentage_discount: ""
    },
    {
        img_url: product3_3,
        product_like_cName: "product-like-btn",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$190",
        product_discount: "$200",
        product_percentage_discount: "-10%"
    },
    {
        img_url: product4_4,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    },
    {
        img_url: product5_5,
        product_like_cName: "product-like-btn",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$190",
        product_discount: "$200",
        product_percentage_discount: "-10%"
    },
    {
        img_url: product6_6,
        product_like_cName: "product-like-btn",
        product_titel: "White Ice Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$120",
        product_discount: "$200",
        product_percentage_discount: "-40%"
    },
    {
        img_url: product7_7,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    },

    {
        img_url: product8_8,
        product_like_cName: "product-like-btn",
        product_titel: "Fullset Black Chair & Sofa",
        product_item_sell: "(360 Sell)",
        product_price: "$999",
        product_discount: "",
        product_percentage_discount: ""
    }
    , {
        img_url: product3_3,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    }
]



export const WishList_ary = [

    {
        id:1,
        img_url: product01_01,
        product_like_cName: "product-like-btn",
        product_titel: "White Ice Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$120",
        product_discount: "$200",
        product_percentage_discount: "-40%"
    },
    {
        id:2,
        img_url: product2_2,
        product_like_cName: "product-like-btn",
        product_titel: "Fullset Black Chair & Sofa",
        product_item_sell: "(360 Sell)",
        product_price: "$999",
        product_discount: "",
        product_percentage_discount: ""
    },
    {
        id:3,
        img_url: product02_02,
        product_like_cName: "product-like-btn",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$190",
        product_discount: "$200",
        product_percentage_discount: "-10%"
    },
    {
        id:4,
        img_url: product4_4,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    },
    {
        id:5,
        img_url: product03_03,
        product_like_cName: "product-like-btn",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$190",
        product_discount: "$200",
        product_percentage_discount: "-10%"
    },
    {
        id:6,
        img_url: product6_6,
        product_like_cName: "product-like-btn",
        product_titel: "White Ice Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$120",
        product_discount: "$200",
        product_percentage_discount: "-40%"
    },
    {
        id:7,
        img_url: product04_04,
        product_like_cName: "Orange Cool Chair",
        product_titel: "Soft Dark Chair",
        product_item_sell: "(400 Sell)",
        product_price: "$99",
        product_discount: "",
        product_percentage_discount: ""
    },

    {
        id:8,
        img_url: product8_8,
        product_like_cName: "product-like-btn",
        product_titel: "Fullset Black Chair & Sofa",
        product_item_sell: "(360 Sell)",
        product_price: "$999",
        product_discount: "",
        product_percentage_discount: ""
    },
]