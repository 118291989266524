import React from 'react'



import Articles_1 from "../../Img/house_planet.jpg"
import Articles_2 from "../../Img/house_light.jpg"
import Articles_3 from "../../Img/house_style.jpg"

function Article() {
    return (
        <>

            <div className='container-fluid mode-div-2'>
                <div className='custom-container'>
                    <div className='container'>
                        <div className='article-div py-4'>
                            <h1>   Article</h1>
                        </div>

                        <div>
                            <img src={Articles_2} alt="" className='img-fluid rounded' />
                        </div>
                        <div className='article-div py-4 mb-0'>
                            <h1 className='mb-0'>Good Lighting Makes The Home More Light</h1>
                        </div>


                        <div className='article-discrip-div'>

                            <div>

                                <p className='article-discrip mb-0 pb-5'>Houseplants are good for your health — and not just for their visual beauty. Why? They essentially do the opposite of what we do when we breathe: release oxygen and absorb carbon dioxide. This not only freshens up the air, but also eliminates harmful toxins. Extensive research by NASA has revealed that houseplants can remove up to 87 per cent of air toxin in 24 hours. Studies have also proven that indoor plants improve concentration and productivity (by up to 15 percent!), reduce stress levels and boost your mood — making them perfect for not just your home but your work space, too.</p>


                                <p className='article-discrip mb-0 pb-5'>

                                    At work, place plants, especially those with broad leaves, on your desk; they will help regulate humidity and increase levels of positivity — seeing greenery and nature help us feel more relaxed and calm, which in turn benefits your every day mood. Indoor plants serve a practical and aesthetic purpose, and will enhance your life.

                                </p>
                                <p className='article-discrip mb-0 pb-5'>
                                    How to Create your Personal Plant Sanctuary <br />
                                    An indoor garden can be your refuge from the outside world, and for many people it is a source of great joy. Whether you live in a small apartment, or a large house, by introducing certain plants into your home, you will start to notice improvements to your health, and overall happiness. As well as enhancing your mood and creating a living space that is soothing to be in, plants can also help with loneliness and depression: caring for a living thing gives us a purpose and is rewarding — especially when you see that living thing bloom and thrive.
                                </p>
                                <p className='article-discrip mb-0 pb-5'>
                                    choose the right plants for the an optimal night's sleep. Although plants release oxygen during the day, it is worth remembering that, at night, when photosynthesis stops, most plants switch things up and release carbon dioxide. However plants such as orchids, succulents, snake plants and bromeliads do the opposite and emit oxygen, making them perfect plants for the bedroom (and getting better sleep). <br />
                                    Beware of too much sun. Most indoor plants don’t like direct midday sun, so please be wary of this when placing plants in your home. There are very obvious warning signs to look out for, such as leaf burn, spotting or sudden leaf-fall. Most plants can be easily rescued, so don’t panic! Often it is a case of not watering them too much, or letting the soil dry out a bit if it is feeling too soggy. Check if your plant is sitting by a cold draft as this can case the leaves to curl and eventually drop off. Organic fertilizers are a great way to revive your plants.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Article