import React from 'react'
import { WishList_ary } from '../Product/ProductAry'
import { GoHeartFill } from "react-icons/go"
import { AiFillStar } from "react-icons/ai"
import { useNavigate } from 'react-router-dom'

function Wishlist() {

    const navigate = useNavigate()

    return (
        <>

            <div className='container-fluid mode-div-2 pading-div'>
                <div className='custom-container'>
                    <div className='container'>
                        <div className='Product-div py-4'>
                            <h1 className='mb-0'>Wishlist</h1>
                        </div>

                        <div className='row'>


                            {WishList_ary ?.map((item, index) => {

                                
                                    return (

                                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 pb-4' key={index} >
                                            <div className='Wishlist-card   '>
                                                <div className='product-simg-div'>
                                                    <img src={item.img_url} alt="" className='Product_img '  onClick={()=>navigate("/productdetail")}/>
                                                    <div className='Wishlist-like-div'>
                                                        <GoHeartFill className='Wishlist-btn' />
                                                    </div>
                                                </div>

                                                <div>
                                                    <p className='Product-sell-titel mb-0'>{item.product_titel}</p>
                                                    <AiFillStar className='product-Star' />
                                                    <AiFillStar className='product-Star' />
                                                    <AiFillStar className='product-Star' />
                                                    <AiFillStar className='product-Star' />
                                                    <AiFillStar className='product-Star' />   <span className='product-item-sell'>{item.product_item_sell}</span>

                                                    <h3 className='product-sell-price'>  {item.product_price} <span className='product-discount'><del>     {item.product_discount}</del></span><span className='product-discount-percentage'>{item.product_percentage_discount}</span></h3>

                                                </div>
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Wishlist