import category_1 from "../../Img/spingbed.jpg"
import category_2 from "../../Img/blue_chair.jpg"
import category_3 from "../../Img/office_desk.jpg"
import category_4 from "../../Img/Cupboard.jpg"
import category_5 from "../../Img/sofa.jpg"
import category_6 from "../../Img/Carpet.jpg"

export const categoryAry = [

    {
        id: 1,
        Category_img: category_1,
        Category_product_name: "Springbed"

    },
    {
        id: 2,
        Category_img: category_2,
        Category_product_name: "Chair"

    },
    {
        id: 3,
        Category_img: category_3,
        Category_product_name: "Office Desk"

    },
    {
        id: 4,
        Category_img: category_4,
        Category_product_name: "Cupboard"

    },
    {
        id: 5,
        Category_img: category_5,
        Category_product_name: "Sofa"

    },
    {
        id: 6,
        Category_img: category_6,
        Category_product_name: "Carpet"
        
    }

]