import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link } from 'react-router-dom';
import { BsHandbagFill } from 'react-icons/bs'
import { BiSearch } from "react-icons/bi"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { BiHeart } from "react-icons/bi"
import { CgMenuRightAlt } from "react-icons/cg"

import black_logo from '../../Img/Logo_black_mood.png'
import white_logo from '../../Img/Logo_white_mood.png'

import cart from "../../Img/shopping-cart.png"
import heart from "../../Img/heart.png"
import notification from "../../Img/notification.png"
import User from "../../Img/profile.png"
import Search from "../../Img/search-normal.png"
import Phone from "../../Img/phone.png"


const Header = ({ mode, togglemode }) => {



    const navigate = useNavigate()


    const handellogin = () => {
        navigate("/login")
    }
    const handelsignup = () => {
        navigate("/signup")
    }





    return (
        <>

            <Navbar expand="lg" className="Header-bg shadow-sm " >
                <Container className='py-1'>

                    <Link className='brand-link' to={"/"}>
                        <Navbar.Brand className='brand-div'>
                            <img src={mode === "dark-mode" ? black_logo : white_logo} alt="" className='brand-logo' />{' '}
                            <p className='brad-name mb-0'>Shoppe.</p>
                        </Navbar.Brand>
                    </Link>
                    <div>

                        {/* <AiOutlineShoppingCart className='search-logo small-logo' onClick={() => navigate("/shoppingcart")} />
                        <BiHeart className='search-logo  small-logo ' onClick={() => navigate("/wishlist")} /> */}
                        <div className='position-relative  d-inline-block'>
                            <img src={cart} alt="" className='color-sm  icon-margin' onClick={() => navigate("/shoppingcart")} />
                            <span className="position-absolute  translate-middle bage-color-1-sm  rounded-circle">1
                                <span className="visually-hidden">New alerts</span>
                            </span>
                        </div>
                        <div className='position-relative  d-inline-block'>
                            <img src={heart} alt="" className='color-sm' onClick={() => navigate("/wishlist")} />
                            <span className="position-absolute  translate-middle bage-color-sm  rounded-circle">0
                                <span className="visually-hidden">New alerts</span>
                            </span>
                        </div>
                        <img src={Phone} alt="" className='color-sm' onClick={() => navigate("contactus")} />
                        <img src={User} alt="" className='color-sm' onClick={() => navigate("/")} />
                        <img src={Search} alt="" className='color-sm' onClick={() => navigate("/")} />

                        {/* <Navbar.Toggle disabled >
                            <BiSearch className='search-logo ' />
                        </Navbar.Toggle > */}
                        <Navbar.Toggle aria-controls="basic-navbar-nav" >
                            <CgMenuRightAlt className='menu-logo' />
                        </Navbar.Toggle >
                    </div>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="nav-search">
                            <div className="search-box">
                                <input className="search-input" type="text" placeholder="Search Here..." />
                                <button className="search-btn"><img src={Search} alt="" className='searchicon' /></button>
                            </div>
                        </Nav>
                        <Nav className='nav-cart'>

                            <div className='nav-right'>
                               
                                    <div className='position-relative icon-div'>
                                        <img src={cart} alt="" className='color  icon-margin' onClick={() => navigate("/shoppingcart")} />
                                        <span className="position-absolute  translate-middle bage-color-1  rounded-circle">1
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    </div>
                                    <div className='position-relative icon-div '>
                                        <img src={heart} alt="" className='color ' onClick={() => navigate("/wishlist")} />
                                        <span className="position-absolute  translate-middle bage-color  rounded-circle">0
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    </div>
                                    <div className='icon-div'>
                                        <img src={Phone} alt="" className='color' onClick={() => navigate("contactus")} />
                                    </div>
                                    <div className='icon-div'>
                                        <img src={User} alt="" className='color ' onClick={() => navigate("/")} />
                                    </div>

                                    {/* <AiOutlineShoppingCart className='cart' onClick={() => navigate("/shoppingcart")} />
                                 <BiHeart className='heart' onClick={() => navigate("/wishlist")} /> */}
                                    <button className='singup' onClick={handelsignup} >Sign Up</button>
                                    <button className='login' onClick={handellogin}>Log In</button>
                                
                            </div>
                        </Nav>


                    </Navbar.Collapse>

                </Container>
            </Navbar>


        </>
    )
}

export default Header