import React from 'react'
import { useNavigate } from 'react-router-dom'

function ShippingData() {

  const navigate = useNavigate()

  return (
    <div>


      <div className='container-fluid mode-div-2 pading-div'>
        <div className='custom-container'>
          <div className='container'>
            <div className='Product-div py-4'>
              <h1 className='mb-0'>   Shipping Data</h1>
            </div>

            <div className='row'>

              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
                <label htmlFor="" className='shipping-label'>Name</label>
                <input type="text" className='shipping-input' placeholder='Ex : Christian Tanaka' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
                <label htmlFor="" className='shipping-label'>Phone Number </label>
                <input type="text" className='shipping-input' placeholder='Ex : 0548548539543' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
                <label htmlFor="" className='shipping-label'>City</label>
                <input type="text" className='shipping-input' placeholder='Ex : 0548548539543' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
                <label htmlFor="" className='shipping-label'>Districts </label>
                <input type="text" className='shipping-input' placeholder='Ex : Banyumas' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
                <label htmlFor="" className='shipping-label'>Province</label>
                <input type="text" className='shipping-input' placeholder='Ex : 0548548539543' />
              </div>
              <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4'>
                <label htmlFor="" className='shipping-label'>ZIP Code </label>
                <input type="text" className='shipping-input' placeholder='Ex : Banyumas' />
              </div>
              <div className='col-12 mb-4'>
                <label htmlFor="" className='shipping-label'>Address </label>
                <textarea name="" id="" rows="6" className='shipping-input' placeholder='Ex : Pramuka street number 49, Singapore'></textarea>
              </div>
              <div className='btn-div mb-4'>
                <button className='back-to-shop-btn' onClick={()=>navigate("/shoppingcart")}>Back to Shopping Cart</button>
                <button className='next-to-payment-btn' onClick={()=>navigate("/payment")}>Next to Payment</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ShippingData