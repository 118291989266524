import React, { useEffect, useState } from 'react'
import "../../Main.css"

//footer section 
import { BsHandbagFill } from 'react-icons/bs'
import { FaFacebookSquare } from 'react-icons/fa'
import { FaTwitterSquare } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
import { BsFillSunFill } from "react-icons/bs"
import { BsMoonStars } from "react-icons/bs"
import { Link, useNavigate } from 'react-router-dom'
import { BiRightArrowAlt } from "react-icons/bi"
import { FaTwitter } from "react-icons/fa"
import { FaFacebookF } from "react-icons/fa"
import { AiOutlineInstagram } from "react-icons/ai"
import { BiLogoLinkedin } from "react-icons/bi"
import { FaYoutube } from "react-icons/fa"
//footer section 

import black_logo from '../../Img/Logo_black_mood.png'
import white_logo from '../../Img/Logo_white_mood.png'


function Footer({mode ,togglemode}) {

    // // Mode
    // const [mode, setMode] = useState("light-mode")

    // const togglemode = () => {
    //     mode === "light-mode" ? setMode("dark-mode") : setMode("light-mode")
    // }

    // useEffect(() => {
    //     document.body.className = mode;
    // }, [mode])

    // // Mode

    const navigate = useNavigate()





    return (
        <>



            {/* Footer section  */}

            <div className='container-fluid  footer-bg' >
                <div className='custom-container'>
                    <div className='container'>


                        {/* <div className='row py-5'>
                            <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'>

                                <div className='brand-footer-div'>
                                    <BsHandbagFill className='brand-footer-logo' />
                                    <p className='brad-footer-name mb-0'>Shop Bag</p>
                                </div>
                                <p className='mb-0 footer-text '>We are the best furniture platform. We are already working on thousands of future home projects. Trust us, you will surely be satisfied</p>



                                <div className='my-4'>
                                    <input type="checkbox" id='dark-toggle' className='chekbox-input' />
                                    <label htmlFor="dark-toggle" className='label-toggle' onClick={() => togglemode()}>

                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="sun" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path></svg>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="moon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"></path><path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"></path></svg>

                                    </label>
                                </div>




                            </div>
                            <div className='col-xl-2 offset-xl-1 col-lg-2 offset-lg-1 col-md-6 col-sm-6 col-6'>
                                <p className='footer-menu-name mb-0'>Community</p>
                                <p className='footer-sub-menu mb-0'>Job board</p>
                                <p className='footer-sub-menu-2 mb-0'>Career Advice</p>
                                <p className='footer-sub-menu-2 mb-0'>Pricing</p>
                                <p className='footer-sub-menu-2 mb-0'>assessments</p>
                                <p className='footer-sub-menu-2 mb-0'>Powered</p>

                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                <p className='footer-menu-name mb-0'>About Us</p>
                                <p className='footer-sub-menu mb-0'>Pricing</p>
                                <p className='footer-sub-menu-2 mb-0'>Internship</p>
                                <p className='footer-sub-menu-2 mb-0'>Press</p>
                                <p className='footer-sub-menu-2 mb-0'>Blog</p>
                                <p className='footer-sub-menu-2 mb-0'>Contact</p>
                            </div>
                            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                <p className='footer-menu-name mb-0'>Pages</p>
                                <p className='footer-sub-menu mb-0'   onClick={()=>navigate("gallery")}>Interior Designer</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("article")}>Article</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("product")}>Product</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("productdetail")}>Product Detail</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("/shoppingcart")}>Shopping Cart</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("/shippingdata")}>Shipping Data</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("/payment")}>Payment</p>
                                <p className='footer-sub-menu-2 mb-0' onClick={()=>navigate("/wishlist")}>Wishlist</p>
                            </div>

                            <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6 col-6'>
                                <p className='footer-menu-name mb-0'>Contact Us</p>

                                <p className='footer-sub-menu mb-0'>Hello@shopbag.com</p>
                                <p className='footer-sub-menu-2 mb-0'>+91 0000000000</p>

                                <div className='mt-4'>
                                    <FaFacebookSquare className='footer-social' />
                                    <FaTwitterSquare className='footer-social' />
                                    <FaLinkedin className='footer-social' />
                                </div>
                            </div>

                        </div>

                        <div className='row'>
                            <div className='footer-copyright-div'>
                                <div className='col-xl-5  col-lg-5 col-md-12 col-sm-12  col-12'>
                                    <p className='footer-text '> 2023 © Shopbag - All right reserved</p>
                                </div>

                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6  col-6'>
                                    <p className='footer-text '>Term of Service</p>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6 col-sm-6  col-6'>
                                    <p className='footer-text '>Privacy Policy</p>
                                </div>
                                <div className='col-xl-2 col-lg-2 col-md-6  col-sm-6 col-6'>
                                    <p className='footer-text '>Partners & Affiliates</p>
                                </div>
                                <div className='col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6   '>
                                    <p className='footer-text '>Sitemap</p>
                                </div>


                            </div>
                        </div> */}


                        <div className='row py-2'>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                <p className='f2-menu-title'>Product <BiRightArrowAlt className='f-arrow-icon' /></p>

                                <p className='f2-sub-menu'>Routing <span className='f2-bages'>New</span></p>
                                <p className='f2-sub-menu'>Availability <span className='f2-bages-silver'>Popular</span></p>
                                <p className='f2-sub-menu'>Team Schedulling <span className='f2-bages-silver'>Popular</span></p>
                                <p className='f2-sub-menu'>Integrations</p>
                                <p className='f2-menu-sub-title' onClick={() => navigate("/team")}>Teams & Companies <BiRightArrowAlt className='f-arrow-icon' /></p>
                                <p className='f2-menu-sub-title' onClick={() => navigate("/pricing")}>Pricing <BiRightArrowAlt className='f-arrow-icon' /></p>

                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>

                                <p className='f2-menu-title'>Teams</p>

                                <p className='f2-sub-menu'>Sales </p>
                                <p className='f2-sub-menu'>Marketing </p>
                                <p className='f2-sub-menu'>Customer Success </p>
                                <p className='f2-sub-menu'>Recruiting</p>
                                <p className='f2-sub-menu'>Information Technology</p>
                                <p className='f2-sub-menu'>Revenue Operations</p>


                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                <p className='f2-menu-title'>Industries</p>

                                <p className='f2-sub-menu'>Technology </p>
                                <p className='f2-sub-menu'>Financial services </p>
                                <p className='f2-sub-menu'>Professionsl services </p>
                                <p className='f2-sub-menu'>Education</p>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                <p className='f2-menu-title'>Company </p>

                                <p className='f2-sub-menu'>Customers </p>
                                <p className='f2-sub-menu'>About Us </p>
                                <p className='f2-sub-menu'>Leadership </p>
                                <p className='f2-sub-menu'>Careers <span className='f2-bages'>We're hiring!</span></p>
                                <p className='f2-sub-menu'>Newsroom</p>

                            </div>
                        </div>


                        <div className='row py-2 '>



                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                <p className='f2-menu-title'>Resources </p>

                                <p className='f2-sub-menu'>Blog </p>
                                <p className='f2-sub-menu'>Resource Center</p>
                                <p className='f2-sub-menu'>Compare </p>
                                <p className='f2-sub-menu'>Become a Partner</p>
                                <p className='f2-sub-menu'>Developer Tools</p>

                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>

                                <p className='f2-menu-title'>Downloads</p>

                                <p className='f2-sub-menu'>Calendly for IOS </p>
                                <p className='f2-sub-menu'>Calendly for Android </p>
                                <p className='f2-sub-menu'>Chrome Extension </p>
                                <p className='f2-sub-menu'>Firefox Extension</p>



                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                <p className='f2-menu-title'>Pages</p>

                                <p className='f2-sub-menu' onClick={() => navigate("/gallery")}>Gallery</p>
                                <p className='f2-sub-menu' onClick={() => navigate("/testimonials")}>Testimonials</p>
                                <p className='f2-sub-menu' onClick={() => navigate("/team")}>Team</p>
                                <p className='f2-sub-menu' onClick={() => navigate("/pricing")}>Pricing</p>
                                <p className='f2-sub-menu' onClick={() => navigate("/contactus")}>Contact Us</p>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6'>
                                <p className='f2-menu-title'>Mode</p>

                                <div >
                                    <input type="checkbox" id='dark-toggle' className='chekbox-input' />
                                    <label htmlFor="dark-toggle" className='label-toggle' onClick={() => togglemode(mode)}>

                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="sun" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"></path></svg>
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" className="moon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z"></path><path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"></path></svg>

                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='row py-2 border-row'>


                            <div className='col-xl-9 col-lg-9 col-md-6 col-sm-12 col-12  py-xl-2 py-lg-2'>
                                <div className='footer-2-privacy-div'>

                                    <p className='footer-2-bottom ps-0'>Privacy</p>
                                    <p className='footer-2-bottom'>Terms and Conditions</p>
                                    <p className='footer-2-bottom'>Status</p>
                                    <p className='footer-2-bottom'>Security</p>
                                    <p className='footer-2-bottom'>Cookie Settings</p>
                                    <p className='footer-2-bottom'>Your Privacy Choices</p>
                                </div>
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12  py-xl-12 py-lg-2'>
                                <div className=' d-flex justify-content-end d-md-block'>
                                    <FaTwitter className='social-footer' />
                                    <FaFacebookF className='social-footer' />
                                    <AiOutlineInstagram className='social-footer' />
                                    <BiLogoLinkedin className='social-footer' />
                                    <FaYoutube className='social-footer' />
                                </div>
                            </div>
                        </div>
                        <p className='copy-right'><Link>@CopyrightShopX2023</Link> </p>
                    </div>
                </div>
            </div>



            {/* Footer section  */}



        </>
    )
}

export default Footer