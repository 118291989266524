import React from 'react'
import Slider from "react-slick";
import { AiFillStar } from "react-icons/ai"
import Testimonials_img1 from "../../Img/testimonial_1.jpg"
import Testimonials_img2 from "../../Img/testimonial_2.jpg"
import Testimonials_img3 from "../../Img/testimonial_3.jpg"

function Testimonials() {

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows:false,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]

    }

    return (
        <>

            <div className='container-fluid mode-div-2 pading-div'>
                <div className='custom-container'>
                    <div className='container'>

                        <div className='form-paddding'>

                            <p className='testimonials-dis'>2,224 people have said how good Rareblocks </p>
                            <h1 className='testimonials-heading'>Our happy clients say about us</h1>

                            <div className='row py-xl-5 py-lg-5 py-md-4 py-4 backgound-color'>


                                {/* <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <div className='testimonials-card shadow-sm'>
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />

                                        <p className='testimonials-text'>"You made it so simple. My new site is so much faster and easier to work with than my old site. i just choose the page,make the change."</p>


                                        <div className='d-flex align-items-center '>
                                            <div>
                                                <img src={Testimonials_img1} alt="/" className='testimonials-card-img' />
                                            </div>
                                            <div>
                                                <p className='mb-0 testimonials-card-name' >Leslie Alexander</p>
                                                <p className='mb-0 testimonials-card-company'>Freelance React Developer </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <div className='testimonials-card shadow-sm'>
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />

                                        <p className='testimonials-text'>"Simply the best. Better than all the rest. I'd recommend this product to beginners and advanced users."</p>


                                        <div className='d-flex align-items-center '>
                                            <div>
                                                <img src={Testimonials_img2} alt="/" className='testimonials-card-img' />
                                            </div>
                                            <div>
                                                <p className='mb-0 testimonials-card-name' >Jacob Jones</p>
                                                <p className='mb-0 testimonials-card-company'>Digital Marketer</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <div className='testimonials-card shadow-sm'>
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />
                                        <AiFillStar className='contact-star' />

                                        <p className='testimonials-text'>"I cannot believe that i have got a brand new landing page after getting Omega. it was super easy to edit and publish."</p>


                                        <div className='d-flex align-items-center '>
                                            <div>
                                                <img src={Testimonials_img3} alt="/" className='testimonials-card-img' />
                                            </div>
                                            <div>
                                                <p className='mb-0 testimonials-card-name' >Jenny Wilson</p>
                                                <p className='mb-0 testimonials-card-company'>Graphic Designer</p>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                                <Slider {...settings} >
                                    <div className='col-xl-4 col-lg-4 col-md-12 py-2 '>
                                        <div className='testimonials-card shadow-sm mx-2 '>
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />

                                            <p className='testimonials-text'>"You made it so simple. My new site is so much faster and easier to work with than my old site. i just choose the page,make the change."</p>


                                            <div className='d-flex align-items-center '>
                                                <div>
                                                    <img src={Testimonials_img1} alt="/" className='testimonials-card-img' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 testimonials-card-name' >Leslie Alexander</p>
                                                    <p className='mb-0 testimonials-card-company'>Freelance React Developer </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div> 
                                    <div className='col-xl-4 col-lg-4 col-md-12  py-2 '>
                                        <div className='testimonials-card shadow-sm mx-2'>
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />

                                            <p className='testimonials-text'>"Simply the best. Better than all the rest. I'd recommend this product to beginners and advanced users."</p>


                                            <div className='d-flex align-items-center '>
                                                <div>
                                                    <img src={Testimonials_img2} alt="/" className='testimonials-card-img' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 testimonials-card-name' >Jacob Jones</p>
                                                    <p className='mb-0 testimonials-card-company'>Digital Marketer</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-12  py-2  '>
                                        <div className='testimonials-card shadow-sm mx-2'>
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />

                                            <p className='testimonials-text'>"I cannot believe that i have got a brand new landing page after getting Omega. it was super easy to edit and publish."</p>


                                            <div className='d-flex align-items-center '>
                                                <div>
                                                    <img src={Testimonials_img3} alt="/" className='testimonials-card-img' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 testimonials-card-name' >Jenny Wilson</p>
                                                    <p className='mb-0 testimonials-card-company'>Graphic Designer</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-12   py-2'>
                                        <div className='testimonials-card shadow-sm mx-2'>
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />

                                            <p className='testimonials-text'>"You made it so simple. My new site is so much faster and easier to work with than my old site. i just choose the page,make the change."</p>


                                            <div className='d-flex align-items-center '>
                                                <div>
                                                    <img src={Testimonials_img1} alt="/" className='testimonials-card-img' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 testimonials-card-name' >Leslie Alexander</p>
                                                    <p className='mb-0 testimonials-card-company'>Freelance React Developer </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-12  py-2 '>
                                        <div className='testimonials-card shadow-sm mx-2'>
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />

                                            <p className='testimonials-text'>"Simply the best. Better than all the rest. I'd recommend this product to beginners and advanced users."</p>


                                            <div className='d-flex align-items-center '>
                                                <div>
                                                    <img src={Testimonials_img2} alt="/" className='testimonials-card-img' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 testimonials-card-name' >Jacob Jones</p>
                                                    <p className='mb-0 testimonials-card-company'>Digital Marketer</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-12  py-2 '>
                                        <div className='testimonials-card shadow-sm mx-2'>
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />
                                            <AiFillStar className='contact-star' />

                                            <p className='testimonials-text'>"I cannot believe that i have got a brand new landing page after getting Omega. it was super easy to edit and publish."</p>


                                            <div className='d-flex align-items-center '>
                                                <div>
                                                    <img src={Testimonials_img3} alt="/" className='testimonials-card-img' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 testimonials-card-name' >Jenny Wilson</p>
                                                    <p className='mb-0 testimonials-card-company'>Graphic Designer</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Slider>
                            </div>
                            <div className='d-flex justify-content-center py-xl-5 py-lg-5 py-md-4 py-4 '>
                                <div>
                                    <p className='review-counter'>Check all 2,224 reviews</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Testimonials
