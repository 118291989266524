import React, { useState } from 'react'
import Emoji from "../../Img/Emoji.png"

function Pricing() {


    const [monthly, setMonthly] = useState(true)




    return (
        <>
            <div className='container-fluid mode-div-2 pading-div'>
                <div className='custom-container'>
                    <div className='container'>
                        <div className='form-paddding'>
                            <h1 className='pricing-heading'> Make the wise decision <br /> for your business</h1>
                            <p className='testimonials-dis'>Choose from our affordable 3 Packages </p>

                            <div className='row  mt-xl-5 mt-lg-5'>

                                <div className='d-flex justify-content-center align-items-center pb-3'>
                                    <p className='pricimg-toggel'>Monthly</p>  <input type="checkbox" id='checkbox' className='pricing-cheak ' onChange={() => { setMonthly((k) => !k) }} /><label htmlFor="checkbox" className='pricing-cheakbox'>Toggle</label> <p className='pricimg-toggel'> Yearly</p>
                                </div>

                                <div className='pricing-div'>
                                    <div className=' pricing-card'>


                                        <p className='pricing-category'>Basic</p>
                                        <h1 className='pricing-price ' >{monthly ? "$99" : "$999"}<span className='membership-time'>{monthly ? "/Mo" : "/Yr"}</span></h1>


                                        <p className='pricing-dis'>Best for small business owners,
                                            startups who needes landing page for their business
                                        </p>
                                        <button className='pricing-btn' >Get started</button>
                                        <p className='pricing-included'>What's included:</p>
                                        <p className='included-item'>130+ Coded Blocks</p>
                                        <p className='included-item'>Best for Developers ,Freelancers</p>
                                        <p className='included-item'>Made With TailwindCSS</p>
                                    </div>
                                    <div className='  pricing-select-card '>
                                        <div className='emoji-div'>
                                            <div>
                                                <p className='pricing-select-category'>Premium</p>
                                                <h1 className='pricing-select-price'>{monthly ? "$199" : "$1999"}<span className='membership-time'>{monthly ? "/Mo" : "/Yr"}</span></h1>

                                            </div>
                                            <img src={Emoji} alt="" className='emoji' />
                                        </div>


                                        <p className='pricing-dis'>Best for medium business owners,
                                            startups who needes landing page for their business
                                        </p>
                                        <button className='pricing-select-btn' >Get started</button>
                                        <p className='pricing-select-included'>What's included:</p>
                                        <p className='included-item'>130+ Coded Blocks</p>
                                        <p className='included-item'>Best for Developers ,Freelancers</p>
                                        <p className='included-item'>Made With TailwindCSS</p>
                                        <p className='included-item'>Premium Support</p>
                                        <p className='included-item'>Future Updates</p>

                                    </div>
                                    <div className=' pricing-card '>


                                        <p className='pricing-category'>Enterprise</p>
                                        <h1 className='pricing-price'>{monthly ? "$399" : "$4224"}<span className='membership-time'>{monthly ? "/Mo" : "/Yr"}</span></h1>


                                        <p className='pricing-dis'>Best for Large business owners,
                                            startups who needes landing page for their business
                                        </p>
                                        <button className='pricing-btn' >Get started</button>
                                        <p className='pricing-included'>What's included:</p>
                                        <p className='included-item'>130+ Coded Blocks</p>
                                        <p className='included-item'>Best for Developers ,Freelancers</p>
                                        <p className='included-item'>Made With TailwindCSS</p>
                                        <p className='included-item'>Premium Support</p>
                                        <p className='included-item'>Future Updates</p>

                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricing
