import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Articles_1 from "../../Img/house_planet.jpg";
import Articles_2 from "../../Img/house_light.jpg";
import Articles_3 from "../../Img/house_style.jpg";
import { FaUserAlt } from "react-icons/fa";
import { BiSolidLockAlt } from "react-icons/bi";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai"
import { FaGoogle } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
function Login() {
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState(

        {
            username: "",
            Password: ""
        }

    )

    const handelchange = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value })
    }


    const handelsubmit = (e) => {
        e.preventDefault()
        if (loginData.username && loginData.Password) {
            toast.success("Login successful")
            navigate("/")

        } else {
            toast.warning("Please fill all data ")
        }
    }

    const [visible, setVisible] = useState(false)

    return (
        <>
            <div className="container-fluid login-bg-div pading-div">
                <div className="custom-container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-xl-4 px-lg-0 login-padding-div ">
                            <div className="loginpage-div">
                                <h1 className="login-p-headind pt-0">Furniture Marketplace</h1>

                                <div>
                                    <Carousel data-bs-theme="dark">
                                        <Carousel.Item>
                                            <img
                                                className="d-block  carousel-img login-carausel  "
                                                src={Articles_3}
                                                alt="First slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block  carousel-img login-carausel "
                                                src={Articles_1}
                                                alt="Second slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block  carousel-img login-carausel"
                                                src={Articles_2}
                                                alt="Third slide"
                                            />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img
                                                className="d-block  carousel-img  login-carausel"
                                                src={Articles_1}
                                                alt="Third slide"
                                            />
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-xl-4 px-lg-0 login-padding-div ">
                            <div className="loginpage-div">
                                <h1 className="login-p-headind   ">Login to Shop Bag</h1>
                                <form action="">
                                    <div className="login-input-div">
                                        <FaUserAlt className="user-icon" />
                                        <input
                                            type="text"
                                            className="user-input"
                                            placeholder="Username"
                                            value={loginData.username}
                                            name="username"
                                            onChange={handelchange}
                                        />
                                    </div>

                                    <div className="login-input-div">
                                        <BiSolidLockAlt className="icon-lock" />
                                        {visible ? <AiFillEye className="icon-eye"  onClick={() => setVisible(!visible)}/> : <AiFillEyeInvisible className="icon-eye" onClick={() => setVisible(!visible)} />   }
                                        <input
                                            type={visible ? "text" : "password"}
                                            className="pass-input"
                                            placeholder="Password"
                                            value={loginData.Password}
                                            name="Password"
                                            onChange={handelchange}
                                            
                                        />
                                    </div>

                                    <div>
                                        <button className="loginp-button " type="submit" onClick={handelsubmit}>
                                            Login
                                        </button>
                                    </div>
                                </form>
                                <p className="account">
                                    Didn’t Have Account?{" "}
                                    <span
                                        className="register"
                                        onClick={() => navigate("/signup")}
                                    >
                                        Register
                                    </span>
                                </p>

                                <div className="social-auth-div">
                                    <button className="google-btn">
                                        <FaGoogle className="google-icon" />
                                        Google
                                    </button>
                                    <button className="facebook-btn">
                                        <FaFacebookF className="facebook-icon" />
                                        Facebook
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}

export default Login;
