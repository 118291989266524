import React, { useState } from 'react'
import product1_1 from "../../Img/whiteiceChair.jpg"
import product2_2 from "../../Img/Black_chair_sofa.webp"
import product3_3 from "../../Img/black_chair.jpg"
import product4_4 from "../../Img/orange_chair.jpg"
import product5_5 from "../../Img/orange-armchair_950558-7442.jpg"
import product6_6 from "../../Img/whiteChairr.jpeg"
import product7_7 from "../../Img/black_chaire_2.webp"
import product8_8 from "../../Img/product__8.jpg"
import product9_9 from "../../Img/product_9.png"
import { useNavigate } from 'react-router-dom'
import Lightbox from 'react-18-image-lightbox';


function Gallery() {

  const images = [
    product1_1,
    product2_2,
    product3_3,
    product4_4,
    product5_5,
    product6_6,
    product7_7,
    product8_8,
    product9_9

  ];

  const [photoIndex, setPhotoIndex] = useState()
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()


  const lightbox_img = [
    {
      id: 0,
      img_link: product1_1
    },
    {
      id: 1,
      img_link: product2_2
    },
    {
      id: 2,
      img_link: product3_3
    },
    {
      id: 3,
      img_link: product4_4
    },
    {
      id: 4,
      img_link: product5_5
    },
    {
      id: 5,
      img_link: product6_6
    },
    {
      id: 6,
      img_link: product7_7
    },
    {
      id: 7,
      img_link: product8_8
    },
    {
      id: 8,
      img_link: product9_9
    },
  ]


  const handellightbox = (id) => {
    setPhotoIndex(id)
    setIsOpen(true)
  }


  return (
    <>
      <div className='container-fluid mode-div-2'>
        <div className='custom-container'>
          <div className='container'>
            <div className='Product-div py-4'>
              <h1 className='mb-0'>Interior Designer</h1>
            </div>

            <div className='row pb-4'>

              {
                lightbox_img?.map((imggs, index) => {
                  return (

                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 ' key={index}>
                      <div >
                        <img src={imggs.img_link} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn ' onClick={() => handellightbox(imggs.id)} />
                      </div>
                    </div>
                  )
                })


              }

              {/* <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 '>
                <img src={product1_1} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn ' onClick={() => setIsOpen(true)} />
                <img src={product5_5} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
                <img src={product2_2} alt="" className='img-fluid rounded mb-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
              </div>
              <div className='col-xl-4 col-bg-4 col-md-4 col-sm-12'>
                <img src={product2_2} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn ' onClick={() => setIsOpen(true)} />
                <img src={product7_7} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
                <img src={product4_4} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
                <img src={product9_9} alt="" className='img-fluid rounded mb-4 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />

              </div>
              <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                <img src={product3_3} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
                <img src={product8_8} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
                <img src={product6_6} alt="" className='img-fluid rounded mb-4 w-100 gallry-btn' onClick={() => setIsOpen(true)} />
                <img src={product1_1} alt="" className='img-fluid rounded pb-0 gallry-btn' onClick={() => setIsOpen(true)} />
              </div> */}

            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}


      {/* {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )} */}
    </>
  )
}

export default Gallery