import React, { useState } from 'react'
import { AiFillStar } from "react-icons/ai"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { BiHeart } from "react-icons/bi"
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressBar from 'react-bootstrap/ProgressBar';

import review_1 from "../../Img/Review_1.jpeg"
import review_2 from "../../Img/Review_2.jpg"
import review_3 from "../../Img/Review_3.jpg"
import review_4 from "../../Img/Review_4.jpg"
import review_5 from "../../Img/Review_5.jpg"
import { BiCalendar } from "react-icons/bi"
import { BiLike } from "react-icons/bi"
import { BiDislike } from "react-icons/bi"
import { Link } from 'react-router-dom';
import { MdSecurity } from "react-icons/md"
import { TbTruckLoading } from "react-icons/tb"
import { BsTruck } from "react-icons/bs"


function ProductDetail() {




  const [image, setImage] = useState({
    img1: "https://i.ibb.co/nRHXqDR/Black-chair-sofa.webp",
    img2: "https://rukminim2.flixcart.com/image/416/416/xif0q/sofa-set/8/m/j/0-0-green-cotton-3-2-2-1-182-88-0-0-65-0-0-woode24-76-2-original-imagqg9uemqnghhq.jpeg?q=70",
    img3: "https://rukminim2.flixcart.com/image/416/416/xif0q/sofa-set/z/j/4/0-0-brown-cream-cotton-3-2-2-1-182-88-0-0-65-0-0-woode3-76-2-original-imagqg8xjaxbpvg9.jpeg?q=70",
    img4: "https://rukminim2.flixcart.com/image/416/416/xif0q/sofa-set/8/m/j/0-0-green-cotton-3-2-2-1-182-88-0-0-65-0-0-woode24-76-2-original-imagqg9uemqnghhq.jpeg?q=70"
  })

  const [activeImg, setActiveImg] = useState(image.img1)

  const images = [
    'https://i.ibb.co/nRHXqDR/Black-chair-sofa.webp',
    'https://rukminim2.flixcart.com/image/416/416/xif0q/sofa-set/8/m/j/0-0-green-cotton-3-2-2-1-182-88-0-0-65-0-0-woode24-76-2-original-imagqg9uemqnghhq.jpeg?q=70',
    'https://rukminim2.flixcart.com/image/416/416/xif0q/sofa-set/z/j/4/0-0-brown-cream-cotton-3-2-2-1-182-88-0-0-65-0-0-woode3-76-2-original-imagqg8xjaxbpvg9.jpeg?q=70',
    'https://rukminim2.flixcart.com/image/416/416/xif0q/sofa-set/8/m/j/0-0-green-cotton-3-2-2-1-182-88-0-0-65-0-0-woode24-76-2-original-imagqg9uemqnghhq.jpeg?q=70',
  ];

  const [count, setCount] = useState(0)

  if (count < 0) {
    setCount(0)

  }


  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)


  // Tabing
  const [key, setKey] = useState('productdetails');
  return (



    <>

      <div className='container-fluid mode-div-2 pading-div'>
        <div className='custom-container'>
          <div className='container'>
            <div className='Product-div py-4'>
              <h1 className='mb-0 '>Product Detail</h1>
            </div>
            <div className='row'>

              <div className='col-xl-6 col-lg-7 col-md-6 col-sm-12 pe-xl-0 pe-lg-0 pe-md-0  '>
                <div className='produt-d-img-div'>
                  <div className='me-xl-3 me-lg-3 me-md-2  product-d-view-div'>
                    <img src={image.img1} alt="" className='product-view-img shadow-sm' onClick={() => setActiveImg(image.img1)} />
                    <img src={image.img2} alt="" className='product-view-img ' onClick={() => setActiveImg(image.img2)} />
                    <img src={image.img3} alt="" className='product-view-img' onClick={() => setActiveImg(image.img3)} />
                    <img src={image.img4} alt="" className='product-view-img mb-0' onClick={() => setActiveImg(image.img4)} />
                  </div>
                  <div>
                    <img src={activeImg} alt="" className='product-img pe-0 ' onClick={() => setIsOpen(true)} />
                  </div>
                  <div className='me-xl-3 me-lg-3 me-md-2  product-d-view-div-2'>
                    <img src={image.img1} alt="" className='product-view-img shadow-sm' onClick={() => setActiveImg(image.img1)} />
                    <img src={image.img2} alt="" className='product-view-img ' onClick={() => setActiveImg(image.img2)} />
                    <img src={image.img3} alt="" className='product-view-img' onClick={() => setActiveImg(image.img3)} />
                    <img src={image.img4} alt="" className='product-view-img mb-0' onClick={() => setActiveImg(image.img4)} />
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-5 col-md-6 col-sm-12 px-0 ps-md-3 ps-xl-0 ps-lg-0  my-xl-0 my-lg-0 my-md-0 my-sm-4 my-4'>

                <h2 className='product-d-heading'>Fullset Black Chair & Sofa</h2>
                <AiFillStar className='product-Star' />
                <AiFillStar className='product-Star' />
                <AiFillStar className='product-Star' />
                <AiFillStar className='product-Star' />
                <AiFillStar className='product-Star' /> <span className='item-sell'>   (360 Sell)</span>

                <h2 className='product-price'>$999</h2>

                <p className='Shoping-d-heading'>Color</p>
                <div >
                  <select name="Black" id="" defaultValue={'Black'} className='form-select'>
                    <option value="Black"> Black</option>
                    <option value="Blue"> Blue</option>
                    <option value="White"> White</option>
                    <option value="Red"> Red</option>
                  </select>
                </div>


                <p className='Shoping-d-heading'>Quantity</p>

                <div className=' product-d-button '>
                  <button className='nagative-btn' onClick={() => setCount(count - 1)}>-</button> {count} <button className='plus-btn' onClick={() => setCount(count + 1)}>+</button>
                </div>
                <div className='button-d-div d-xl-flex d-lg-flex d-md-flex d-sm-none d-none'>
                  <button className='Addto-cart-d'><AiOutlineShoppingCart size={"20px"} className='me-2' />Add to Cart</button>
                  <button className='Addto-washlist-d'><BiHeart size={"20px"} className='me-2' />Add to Wishlist</button>
                </div>
              </div>
            </div>


            {/* <div className='row py-4'>
              <div className='col-xl-6 col-lg-7 col-md-6'>
                <h2 className='item-detail'>Item Detail</h2>
                <p className='item-discrip'>black sofa fullset, made of leather selection from the Himalayan mountains. Knitted by the professional hands of housewives. Making a masterpiece of the best sofa in the world. Very soft and comfortable</p>
              </div>

              <div className='col-xl-6 col-lg-5 col-md-6 px-0 ps-md-3 ps-xl-0 ps-lg-0'>

                <div>
                  <ul >
                    <li className='unorder-list'>High Quality Leather</li>
                    <li className='unorder-list'>High Quality Paint</li>
                    <li className='unorder-list'>High Quality Wood</li>
                    <li className='unorder-list'>Cheap Price</li>
                  </ul>
                </div>

                <div className='button-d-div d-xl-none d-lg-none d-md-none d-sm-block d-block'>
                  <button className='Addto-cart-d'><AiOutlineShoppingCart size={"20px"} className='me-2' />Add to Cart</button>
                  <button className='Addto-washlist-d'><BiHeart size={"20px"} className='me-2' />Add to Wishlist</button>
                </div>
              </div>
            </div> */}


            <div className='row py-4'>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="productdetails" title="Product Details">
                  <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Sed posuere consectetur est at lobortis. Sed posuere consectetur est at lobortis. Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo. Vestibulum id ligula porta felis euismod semper.</p>
                  <p>Vestibulum id ligula porta felis euismod semper. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas faucibus mollis interdum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                </Tab>
                <Tab eventKey="additionalinfo" title="Additional Info">
                  <p>Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Tab>
                <Tab eventKey="delivery" title="Delivery" >
                  <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Cras mattis consectetur purus sit amet fermentum. Maecenas sed diam eget risus varius blandit sit amet non magna. Sed posuere consectetur est at lobortis. Curabitur blandit tempus porttitor. Aenean lacinia bibendum nulla sed consectetur. Nulla vitae elit libero, a pharetra augue. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.</p>
                </Tab>
              </Tabs>
            </div>


            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-12 py-xl-4'>
                <p className='Rating-dis'>Ratings Distribution</p>
                <div>
                  <AiFillStar className='allover-rating' />
                  <AiFillStar className='allover-rating' />
                  <AiFillStar className='allover-rating' />
                  <AiFillStar className='allover-rating' />
                  <AiFillStar className='allover-rating-none' /> <span className='rating-text'>4.2 out of 5</span>
                </div>
                <div className='progress-section'>
                  <div className='progress-main-div'>
                    <div className='progress-div'>
                      <p className='progress-star'>5 Stars</p> <p className='progress-percentage'>82%</p>
                    </div>
                    <div className='progressbar'><ProgressBar now={82} /></div>
                  </div>


                  <div className='progress-main-div'>
                    <div className='progress-div'>
                      <p className='progress-star'>4 Stars</p> <p className='progress-percentage'>8%</p>
                    </div>
                    <div className='progressbar'><ProgressBar now={8} /></div>
                  </div>


                  <div className='progress-main-div'>
                    <div className='progress-div'>
                      <p className='progress-star'>3 Stars</p> <p className='progress-percentage'>5%</p>
                    </div>
                    <div className='progressbar'><ProgressBar now={5} /></div>
                  </div>


                  <div className='progress-main-div'>
                    <div className='progress-div'>
                      <p className='progress-star'>2 Stars</p> <p className='progress-percentage'>3%</p>
                    </div>
                    <div className='progressbar'><ProgressBar now={3} /></div>
                  </div>


                  <div className='progress-main-div'>
                    <div className='progress-div'>
                      <p className='progress-star'>1 Stars</p> <p className='progress-percentage'>2%</p>
                    </div>
                    <div className='progressbar'><ProgressBar now={2} /></div>
                  </div>
                </div>

                <p className='Rating-dis'>Review this product</p>
                <p className='review-dis'>Aenean eu leo quam ornare sem lacinia quam.</p>
                <button className='write-review'>Write a Review</button>


              </div>
              <div className='col-xl-8 col-lg-8 col-md-12 px-xl-5 py-xl-4 px-lg-4'>

                <div className='ratings-review-div'>
                  <h2 className='Ratings-Reviews'>Ratings & Reviews</h2>

                  <div class="form-select-wrapper">
                    <div class="form-select-wrapper">
                      <select class="review-select" defaultValue={'new'}>
                        <option value="popular">Sort by popularity</option>
                        <option value="rating">Sort by average rating</option>
                        <option value="new">Sort by newness</option>
                        <option value="low-to-high">Sort by price: low to high</option>
                        <option value="high-to-low">Sort by price: high to low</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='review-box'>
                  <div className='review-profile'>
                    <div>
                      <img src={review_1} alt="" className='review-img' />
                    </div>
                    <div>
                      <p className='reviwe-writer'> Connor Gibson</p>
                      <p className='reviwe-writeing-data'><span><BiCalendar className='celender' /></span> 14 Jan 2022</p>
                    </div>
                  </div>
                  <div className='review-star-div'>
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' /> <span className='review-recommended'> Highly recommended!</span>
                  </div>
                  <p className='review-box-dis'>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Sed posuere consectetur est at lobortis integer posuere erat ante.</p>
                  <div className='helpful-div'>

                    <p className='review-helpful'>Was this review helpful? </p>  <p className='like-text ps-5'><span><BiLike className='like' />5</span></p>  <p className='like-text'><span><BiDislike className='like' />5</span></p>
                  </div>
                </div>


                <div className='review-box'>
                  <div className='review-profile'>
                    <div>
                      <img src={review_3} alt="" className='review-img' />
                    </div>
                    <div>
                      <p className='reviwe-writer'> Nikolas Brooten</p>
                      <p className='reviwe-writeing-data'><span><BiCalendar className='celender' /></span> 24 Feb 2022</p>
                    </div>
                  </div>
                  <div className='review-star-div'>
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating-none' /> <span className='review-recommended'> Great product</span>
                  </div>
                  <p className='review-box-dis'>Quisque tristique tincidunt metus non aliquam. Quisque ac risus sit amet quam sollicitudin vestibulum vitae malesuada libero. Mauris magna elit, suscipit non ornare et, blandit a tellus. Pellentesque dignissim ornare faucibus mollis.</p>
                  <div className='helpful-div'>

                    <p className='review-helpful'>Was this review helpful? </p>  <p className='like-text ps-5'><span><BiLike className='like' />5</span></p>  <p className='like-text'><span><BiDislike className='like' />5</span></p>
                  </div>
                </div>



                <div className='review-box'>
                  <div className='review-profile'>
                    <div>
                      <img src={review_2} alt="" className='review-img' />
                    </div>
                    <div>
                      <p className='reviwe-writer'> Pearce Frye</p>
                      <p className='reviwe-writeing-data'><span><BiCalendar className='celender' /></span> 28 Feb 2022</p>
                    </div>
                  </div>
                  <div className='review-star-div'>
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating-none' />
                    <AiFillStar className='allover-rating-none' /> <span className='review-recommended'> Could be better</span>
                  </div>
                  <p className='review-box-dis'>Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Etiam porta sem malesuada magna mollis.</p>
                  <div className='helpful-div'>

                    <p className='review-helpful'>Was this review helpful? </p>  <p className='like-text ps-5'><span><BiLike className='like' />5</span></p>  <p className='like-text'><span><BiDislike className='like' />5</span></p>
                  </div>
                </div>



                <div className='review-box'>
                  <div className='review-profile'>
                    <div>
                      <img src={review_4} alt="" className='review-img' />
                    </div>
                    <div>
                      <p className='reviwe-writer'> Nikolas Brooten</p>
                      <p className='reviwe-writeing-data'><span><BiCalendar className='celender' /></span> 04 Apr 2022</p>
                    </div>
                  </div>
                  <div className='review-star-div'>
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating-none' />
                    <AiFillStar className='allover-rating-none' />
                    <AiFillStar className='allover-rating-none' />
                    <AiFillStar className='allover-rating-none' /> <span className='review-recommended'> I am going to return it</span>
                  </div>
                  <p className='review-box-dis'>Nullam id dolor id nibh ultricies vehicula ut id. Cras mattis consectetur purus sit amet fermentum. Aenean eu leo quam. Pellentesque ornare sem lacinia aenean bibendum nulla consectetur.</p>
                  <div className='helpful-div'>

                    <p className='review-helpful'>Was this review helpful? </p>  <p className='like-text ps-5'><span><BiLike className='like' />5</span></p>  <p className='like-text'><span><BiDislike className='like' />5</span></p>
                  </div>
                </div>



                <div className='review-box'>
                  <div className='review-profile'>
                    <div>
                      <img src={review_5} alt="" className='review-img' />
                    </div>
                    <div>
                      <p className='reviwe-writer'> Lou Bloxham</p>
                      <p className='reviwe-writeing-data'><span><BiCalendar className='celender' /></span> 03 May 2022</p>
                    </div>
                  </div>
                  <div className='review-star-div'>
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating' />
                    <AiFillStar className='allover-rating-none' />
                    <AiFillStar className='allover-rating-none' /> <span className='review-recommended'> I am going to return it</span>
                  </div>
                  <p className='review-box-dis'>Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                  <div className='helpful-div'>

                    <p className='review-helpful'>Was this review helpful? </p>  <p className='like-text ps-5'><span><BiLike className='like' />5</span></p>  <p className='like-text'><span><BiDislike className='like' />5</span></p>
                  </div>
                </div>



                <nav aria-label="Page navigation example">
                  <ul class="pagination  shadow-sm ">
                    <li class="page-item">
                      <Link class="page-link" to={"#"} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only"></span>
                      </Link>
                    </li>
                    <li class="page-item"><Link class="page-link">1</Link></li>
                    <li class="page-item"><Link class="page-link">2</Link></li>
                    <li class="page-item"><Link class="page-link">3</Link></li>
                    <li class="page-item">
                      <Link class="page-link" to={"#"} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only"></span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>




            </div>


          </div>
        </div>

      </div>
      <div className='container-fluid shipping-bg'>
        <div className='custom-container '>
          <div className='container'>
            <div className='row' >

              <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
                <div className='shipping-div'>
                  <div><BsTruck className='shipping-cart' /></div>
                  <div className='px-xl-4 px-lg-3 px-md-2  px-4'>
                    <p className='free-shipping'>Free Shipping</p>
                    <p className='shipping-dis'>Duis mollis gravida commodo id luctus erat porttitor ligula, eget lacinia odio sem.</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>

                <div className='shipping-div'>
                  <div><TbTruckLoading className='shipping-cart' /></div>
                  <div className='px-xl-4 px-lg-3 px-md-2 px-4'>
                    <p className='free-shipping'>30 Days Return</p>
                    <p className='shipping-dis'>Duis mollis gravida commodo id luctus erat porttitor ligula, eget lacinia odio sem.</p>
                  </div>
                </div>

              </div>
              <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
              <div className='shipping-div'>
                  <div><MdSecurity className='shipping-cart' /></div>
                  <div className='px-xl-4 px-lg-3 px-md-2 px-4'>
                    <p className='free-shipping'>2-Years Warranty</p>
                    <p className='shipping-dis'>Duis mollis gravida commodo id luctus erat porttitor ligula, eget lacinia odio sem.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}


    </>
  )
}

export default ProductDetail