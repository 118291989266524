import React from 'react'
import loader from "../../Img/loderr_gif.gif"

function Loader() {
  return (
    <div>
        <div className='lodader-div'>
            <div>
                <img src={loader} alt=""  className='loader-img'/>
            </div>
        </div>
    </div>
  )
}

export default Loader