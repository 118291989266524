import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import "../../Main.css"
import HeroImg from "../../Img/hero_sofa.jpg"
import bubble from "../../Img/Bubble Accessories.png"


// best Seller
import bestselimg from "../../Img/istockphoto-611094476-612x612.jpg"
import bestselimg1 from "../../Img/61IkEs05ycL._SX466_.jpg"
import bestselimg2 from "../../Img/product__3.webp"
import { GoHeartFill } from "react-icons/go"
import { AiFillStar } from "react-icons/ai"
// best Seller

// office section 

import offfice1 from "../../Img/hero_chair.jpg"
import offfice2 from "../../Img/hero_desk.jpg"

// office section 

// product section

import { product } from '../Product/ProductAry'

// product section


// category section 

import { BsArrowRight } from "react-icons/bs"

import { categoryAry } from '../CategoryAry/CategoryAry'
// category section 


// carousel section 

import Carousel from 'react-bootstrap/Carousel';
import carousel_1 from "../../Img/carousel_card_1.jpg"
import carousel_2 from "../../Img/carousel_card_2.jpg"
import carousel_3 from "../../Img/carousel_card_3.jpg"
import carousel_4 from "../../Img/carousel_card_4.jpg"
// carousel section 

// Articles section

import Articles_1 from "../../Img/house_planet.jpg"
import Articles_2 from "../../Img/house_light.jpg"
import Articles_3 from "../../Img/house_style.jpg"


// Articles section


function Home() {

    const navgate = useNavigate()

    const handelClick = () => {
        navgate("/article")

    }
    const handelproductdetails = () => {
        navgate("/productdetail")
    }

    return (
        <>

            <div className='container-fluid pading-div px-0 overflow-hidden'>

                <div className='row bg-color pb-5  '>
                    <div className='col-xl-6 col-lg-6 col-md-12'
                    >
                        <img src={HeroImg} alt="" className='heroimg wow fadeInLeft ' data-wow-duration="2s" data-wow-delay="0s" />
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-12 ' >


                        <div className='hero-div '>


                            <div className='hero-content wow fadeInRight' data-wow-duration="2s" data-wow-delay="0s" >

                                <h1 className='hero-main-text'>Crafting Better Experience For Your Family</h1>
                                <p className='hero-main-disp '>We are the best furniture platform. We are already working on thousands of future home projects. Trust us, you will surely be satisfied</p>
                                <div className='button-div'>
                                    <button className='shopping' onClick={() => navgate("/product")}>Go Shopping</button>
                                    <button className='designer' onClick={() => navgate("/gallery")}>Interior Designer</button>
                                </div>
                            </div>

                            <img src={bubble} alt="" className='bubble' />

                        </div>

                    </div>
                </div>

            </div>


            {/* best Seller */}

            <div className='container-fluid mode-div-1'>
                <div className='custom-container'>

                    <div className='container '>
                        <div className='row py-4'>
                            <div className='best-seller-div'>
                                <h1 className='mb-0 best-seller pb-4'>Best Seller</h1>
                                <p className='mb-0 see-all' onClick={() => navgate("/product")}>See All</p>
                            </div>

                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12' >
                                <div className='best-seller-card wow fadeInUp' data-wow-duration="2s" data-wow-delay="0s" >
                                    <div className='best-simg-div'>
                                        <img src={bestselimg} alt="" className='product_img' onClick={handelproductdetails} />
                                        <div className='like-div' >
                                            <GoHeartFill className='like-btn' />
                                        </div>
                                    </div>

                                    <div>
                                        <p className='best-sell-titel mb-0'>Green Slepper Sofa</p>
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />   <span className='item-sell'>   (400 Sell)</span>

                                        <h3 className='sell-price'>$124</h3>
                                    </div>
                                </div>

                            </div>

                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'  >
                                <div className='best-seller-card wow fadeInUp' data-wow-duration="2s" data-wow-delay="0s" >
                                    <div className='best-simg-div'>
                                        <img src={bestselimg1} alt="" className='product_img' onClick={handelproductdetails} />
                                        <div className='like-div'>
                                            <GoHeartFill className='like-btn' />
                                        </div>
                                    </div>

                                    <div>
                                        <p className='best-sell-titel mb-0'>Yellow Chair X Banana Kitty</p>
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />   <span className='item-sell'>   (400 Sell)</span>

                                        <h3 className='sell-price'>  $424 <span className='discount'><del>     $699</del></span><span className='discount-percentage'>-42%</span></h3>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12  '  >
                                <div className='best-seller-card wow fadeInUp' data-wow-duration="2s" data-wow-delay="0s">
                                    <div className='best-simg-div'>
                                        <img src={bestselimg2} alt="" className='product_img' onClick={handelproductdetails} />
                                        <div className='like-div'>
                                            <GoHeartFill className='like-btn' />
                                        </div>
                                    </div>

                                    <div>
                                        <p className='best-sell-titel mb-0'>Fullset Grey Sofa With Pillow</p>
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />
                                        <AiFillStar className='bests-Star' />   <span className='item-sell'>   (400 Sell)</span>

                                        <h3 className='sell-price'>  $799 <span className='discount'><del>     $820</del></span><span className='discount-percentage'>-3%</span></h3>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* best Seller */}



            {/* category section  */}


            <div className='container-fluid mode-div-2'>
                <div className='custom-container'>
                    <div className='container '>

                        <div className='product-div pb-4'>
                            <h1 className='mb-0 py-xl-2 articles'>Category</h1>
                        </div>

                        <div className='row'>
                            {
                                categoryAry?.map((item, index) => {
                                    return (
                                        <div className="col-xl-4 col-lg-4 col-md-6 co-sm-6 col-6 mb-4" key={index} onClick={() => navgate("/product")} >
                                            <div className='category-card wow fadeInDown ' data-wow-duration="2s" data-wow-delay="0s">
                                                <img src={item.Category_img} alt="" className='category-img ' />
                                                <div className='category-content'>
                                                    <h1 className='mb-0 category-text'>{item.Category_product_name}</h1>
                                                    <button className='category-button' >Shop Now  <BsArrowRight className='category-arrow' /> </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* category section  */}


            {/* office section

            <div className='container-fluid  '>
                <div className='row'>


                    <div className='col-xl-6 col-lg-6 col-md-6 px-0'  >
                        <div className='position-relative'>
                            <img src={offfice1} alt="" className='img-fluid w-100 office' onClick={() => navgate("/gallery")}  />
                            <h1 className='office-1-text'>Office Chair</h1>
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 px-0' >
                        <div className='position-relative'>
                            <img src={offfice2} alt="" className='img-fluid w-100 office' onClick={() => navgate("/gallery")}  />
                            <h1 className='office-1-text'>Office Desk</h1>
                        </div>
                    </div>
                </div>
            </div>

            office section */}




            {/* product section */}

            <div className='container-fluid  mode-div-1 '>
                <div className='custom-container'>

                    <div className='container '>
                        <div className='row pb-4'>
                            <div className='best-seller-div pt-4  '>
                                <h1 className='mb-0 best-seller'>Product</h1>
                                <p className='mb-0 see-all' onClick={() => navgate("/product")}>See All</p>
                            </div>
                            {product?.map((item, index) => {
                                return (
                                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6' key={item.id} >
                                        <div className='best-seller-card wow fadeInDown  mt-4' data-wow-duration="2s" data-wow-delay="0s"   >
                                            <div className='product-simg-div' >
                                                <img src={item.img_url} alt="" className='Product_img ' onClick={handelproductdetails} />
                                                <div className='product-like-div'>
                                                    <GoHeartFill className='product-like-btn' />
                                                </div>
                                            </div>

                                            <div>
                                                <p className='Product-sell-titel mb-0'>{item.product_titel}</p>
                                                <AiFillStar className='product-Star' />
                                                <AiFillStar className='product-Star' />
                                                <AiFillStar className='product-Star' />
                                                <AiFillStar className='product-Star' />
                                                <AiFillStar className='product-Star' />   <span className='product-item-sell'>{item.product_item_sell}</span>

                                                <h3 className='product-sell-price'>  {item.product_price} <span className='product-discount'><del>     {item.product_discount}</del></span><span className='product-discount-percentage'>{item.product_percentage_discount}</span></h3>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>


            {/* product section */}







            {/* carousel section  */}

            <div className='container-fluid py-4' style={{ background: "black" }} >
                <div className='custom-container'>
                    <div className='container'>

                        <div className='row py-4'>

                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' >
                                <div className='img-border wow fadeInLeft' data-wow-duration="2s" data-wow-delay="0s" >
                                    <div className='img-carousel-div'>
                                        {/* <img src={carousel_1} alt="" className='img-fluid img-carousel' />  */}


                                        <Carousel data-bs-theme="dark" >
                                            <Carousel.Item >
                                                <img
                                                    className="d-block  carousel-img  "
                                                    src={carousel_1}
                                                    alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img
                                                    className="d-block  carousel-img "
                                                    src={carousel_2}
                                                    alt="Second slide"
                                                />

                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img
                                                    className="d-block  carousel-img "
                                                    src={carousel_3}
                                                    alt="Third slide"
                                                />

                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img
                                                    className="d-block  carousel-img "
                                                    src={carousel_4}
                                                    alt="Forth slide"
                                                />

                                            </Carousel.Item>

                                        </Carousel>

                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' >
                                <div className='d-flex align-items-center h-100 ' >
                                    <div className='px-xl-4 px-lg-4  px-md-0 japanese-animation wow fadeInRight' data-wow-duration="2s" data-wow-delay="0s">
                                        <h1 className='mb-0 Japanese-text mb-xl-4 mb-lg-4 mb-md-2'>Japanese Cupboard</h1>
                                        <p className='japanese-cardbord-discp mb-0'>The combination of vintage, minimalist and classic makes this Japanese design cupboard the right choice. This cabinet was chosen by millennials to complement their housing</p>
                                        <button className='Japanese-button' onClick={() => navgate("/productdetail")}>Shop Now  <BsArrowRight className='Japanese-arrow' /> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* carousel section  */}


            {/* Articles section  */}
            <div className='container-fluid pb-4 mode-div-2'>
                <div className='custom-container'>
                    <div className='container '>

                        <div className='product-div py-4'>
                            <h1 className='mb-0 py-xl-2 articles'>Articles</h1>
                        </div>

                        <div className='row'>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'  >
                                <div className='articles-card wow fadeInUp' onClick={handelClick} data-wow-duration="2s" data-wow-delay="0s">
                                    <img src={Articles_1} alt="" className=' articles-img ' />
                                    <p className='articles-title mb-0'>Plants make the atmosphere of the house come alive</p>
                                    <p className='articles-discrip mb-0'>Plants make the atmosphere of the house come alive, we should gain our...</p>
                                    <button className='articles-button'  >Read More <BsArrowRight className='articles-rightarrow' /></button>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12' >
                                <div className='articles-card wow fadeInUp' onClick={handelClick} data-wow-duration="2s" data-wow-delay="0s" >
                                    <img src={Articles_2} alt="" className=' articles-img' />
                                    <p className='articles-title mb-0'>Good Lighting Makes The Home More Light</p>
                                    <p className='articles-discrip mb-0'>Good lighting makes the home more light, the combination of light and sun give...</p>
                                    <button className='articles-button' >Read More <BsArrowRight className='articles-rightarrow' /></button>
                                </div>
                            </div>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12' >
                                <div className='articles-card wow fadeInUp' onClick={handelClick} data-wow-duration="2s" data-wow-delay="0s">
                                    <img src={Articles_3} alt="" className=' articles-img' />
                                    <p className='articles-title mb-0'>Classic Style Makes Houses Look More Expensive</p>
                                    <p className='articles-discrip mb-0'>Classic style makes houses look more expensive, the vintage effect that verry...</p>
                                    <button className='articles-button' >Read More <BsArrowRight className='articles-rightarrow' /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Articles section  */}




        </>
    )

}

export default Home