import React, { useState } from 'react'
import { product_2 } from './ProductAry'
import { GoHeartFill } from "react-icons/go"
import { AiFillStar } from "react-icons/ai"
import { IoIosArrowUp } from "react-icons/io"
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom'


function Product() {




    const navigate = useNavigate()


    return (
        <>
            <div className='container-fluid mode-div-2 pading-div'>
                <div className='custom-container'>
                    <div className='container'>
                        <div className='Product-div py-4 '>
                            <h1 className='mb-0'>Product</h1>
                        </div>

                        <div className='row'>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-sm-12' >


                                <Accordion defaultActiveKey={['0','1','2','3']} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header >Color   </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>Red</label>
                                            </div>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>Green</label>
                                            </div>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>Yellow</label>
                                            </div>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>Black</label>
                                            </div>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>Brown</label>
                                            </div>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>White</label>
                                            </div>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>Orange</label>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Rating</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='color-div'>
                                                <input type="checkbox" className='checkbox-product' />
                                                <label htmlFor="" className='label-product'>4 Star Up</label>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Price</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='price-div'>
                                                <label htmlFor="" className='label-doller'>$</label>
                                                <input type="text" className='input-price' placeholder='Maximum Price' />
                                            </div>
                                            <div className='price-div'>
                                                <label htmlFor="" className='label-doller'>$</label>
                                                <input type="text" className='input-price' placeholder='Minimum Price' />
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Category</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="Accordion">
                                                <li className="Accordion-item">
                                                    <div className='Sub-menu-div'>
                                                        <IoIosArrowUp className='arrowAccordion' />   <label htmlFor="checkboxes-menu1">Chair</label></div>
                                                    <input id="checkboxes-menu1" type="checkbox" name="menu" />
                                                    <ul className="Accordion-subElements">
                                                        <li className="Accordion-subElement"><a href="/">Office Chair</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Family Chair</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Reading Chair</a></li>

                                                    </ul>
                                                </li>

                                                <li className="Accordion-item">
                                                    <div className='Sub-menu-div'>
                                                        <IoIosArrowUp className='arrowAccordion' /> <label htmlFor="checkboxes-menu2">Desk</label></div>
                                                    <input id="checkboxes-menu2" type="checkbox" name="menu" />
                                                    <ul className="Accordion-subElements">
                                                        <li className="Accordion-subElement"><a href="/">Office Desk</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Home Desk</a></li>

                                                    </ul>
                                                </li>
                                                <li className="Accordion-item">
                                                    <div className='Sub-menu-div'>
                                                        <IoIosArrowUp className='arrowAccordion' /> <label htmlFor="checkboxes-menu3">Bed</label></div>
                                                    <input id="checkboxes-menu3" type="checkbox" name="menu" />
                                                    <ul className="Accordion-subElements">
                                                        <li className="Accordion-subElement"><a href="/">Office Desk</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Home Desk</a></li>

                                                    </ul>
                                                </li>
                                                <li className="Accordion-item">
                                                    <div className='Sub-menu-div'>
                                                        <IoIosArrowUp className='arrowAccordion' /> <label htmlFor="checkboxes-menu4">Carpet</label></div>
                                                    <input id="checkboxes-menu4" type="checkbox" name="menu" />
                                                    <ul className="Accordion-subElements">
                                                        <li className="Accordion-subElement"><a href="/">Office Desk</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Home Desk</a></li>

                                                    </ul>
                                                </li>
                                                <li className="Accordion-item">
                                                    <div className='Sub-menu-div'>
                                                        <IoIosArrowUp className='arrowAccordion' /> <label htmlFor="checkboxes-menu5">Sofa</label></div>
                                                    <input id="checkboxes-menu5" type="checkbox" name="menu" />
                                                    <ul className="Accordion-subElements">
                                                        <li className="Accordion-subElement"><a href="/">Office Desk</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Home Desk</a></li>

                                                    </ul>
                                                </li>
                                                <li className="Accordion-item">
                                                    <div className='Sub-menu-div'>
                                                        <IoIosArrowUp className='arrowAccordion' /> <label htmlFor="checkboxes-menu6">Cupboard</label></div>
                                                    <input id="checkboxes-menu6" type="checkbox" name="menu" />
                                                    <ul className="Accordion-subElements">
                                                        <li className="Accordion-subElement"><a href="/">Office Desk</a></li>
                                                        <li className="Accordion-subElement"><a href="/">Home Desk</a></li>

                                                    </ul>
                                                </li>
                                            </ul>



                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>



                            </div>
                            <div className='col-xl-9 col-lg-9 col-md-9 col-sm-12'>
                                <div className='row'>
                                    {product_2?.map((item, index) => {
                                        return (
                                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6' key={index}>
                                                <div className='product-seller-card_2   my-3'>
                                                    <div className='product-page-simg-div'>
                                                        <img src={item.img_url} alt="" className='Product_img ' onClick={() => navigate("/productdetail")} />
                                                        <div className='product-like-div'>
                                                            <GoHeartFill className='product-like-btn' />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <p className='Product-sell-titel mb-0'>{item.product_titel}</p>
                                                        <AiFillStar className='product-Star' />
                                                        <AiFillStar className='product-Star' />
                                                        <AiFillStar className='product-Star' />
                                                        <AiFillStar className='product-Star' />
                                                        <AiFillStar className='product-Star' /><span className='product-item-sell'>{item.product_item_sell}</span>

                                                        <h3 className='product-sell-price'>  {item.product_price} <span className='product-discount'><del>     {item.product_discount}</del></span><span className='product-discount-percentage'>{item.product_percentage_discount}</span></h3>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Product