import React from 'react'
import Team_1 from "../../Img/our_team_1.png"
import Team_2 from "../../Img/our_team_2.png"
import Team_3 from "../../Img/our_team_3.png"
import Team_4 from "../../Img/our_team_4.png"
import Team_5 from "../../Img/our_team_5.png"
import Team_6 from "../../Img/our_team_6.png"
import Team_7 from "../../Img/our_team_7.png"
import Team_8 from "../../Img/our_team_8.png"


import Profile_1 from "../../Img/profile_1.png"
import Profile_2 from "../../Img/profile_2.png"
import Profile_3 from "../../Img/Profile_3.png"
import Profile_4 from "../../Img/Profile_4.jpg"

import { TfiTwitter } from "react-icons/tfi"
import { LiaFacebook } from "react-icons/lia"
import { BsDribbble } from "react-icons/bs"


function Team() {
  return (
    <>
      <div className='container-fluid mode-div-2 pading-div'>
        <div className='custom-container'>
          <div className='container'>
            <div className='Product-div py-4 '>
              <div className='team-head-div'>
                <h1 className=' text-center'>Meet Our Team</h1>
                <p className='team-discrip'>Problems trying to resolove the conflict between <br className='d-none d-xl-blocl d-lg-block d-md-block' /> the two major realms of classical physics: Newtonian mechanics</p>
              </div>
            </div>

            <div className='row  pb-xl-4 pb-lg-4 pb-md-4 pb-sm-3 pb-3'>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_1} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Jerome Bell</h3>
                  <p className='mb-0 company-name'>IBM</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_2} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Brooklyn Simmons</h3>
                  <p className='mb-0 company-name'>eBay</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_3} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Ronald Richard </h3>
                  <p className='mb-0 company-name'>Starbucks</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_4} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Floyd Miles</h3>
                  <p className='mb-0 company-name'>Facebook</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_5} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>jane Cooper</h3>
                  <p className='mb-0 company-name'>Mitsubishi</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_6} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Robert Fox </h3>
                  <p className='mb-0 company-name'>IBM</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_7} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Leslie Alexander</h3>
                  <p className='mb-0 company-name'>The Walt Disney Company</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
                <div className='team-card '>
                  <img src={Team_8} alt="" className=' team-employe-img' />
                  <h3 className='mb-0 Employe-name'>Jacob Jones </h3>
                  <p className='mb-0 company-name'>Starbucks</p>
                  <div className='social-div-team'>
                    <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                  </div>
                </div>
              </div>
            </div>



            <div className='Product-div py-4 '>
              <div className='team-head-div'>
                <h2 className=' text-center '>Think beyond the box and be creative. <br className='d-none d-xl-block d-lg-block' /> Lighthouse can help you make a difference.</h2>

              </div>
            </div>


            {/* <div className='row py-xl-4 py-lg-4 py-md-4 py-sm-3 py-3'>

              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_1} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Jacob Jones</p>
                    <p className='mb-0 profile-company'>Mitsubishi</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6  py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_2} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Kathryn Murphy</p>
                    <p className='mb-0 profile-company'>Starbucks</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_3} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Eleanor Pena</p>
                    <p className='mb-0 profile-company'>IBM</p>
                  </div>
                </div>
              </div>


              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_3} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Marvin McKinney</p>
                    <p className='mb-0 profile-company'>Starbucks</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_1} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Floyd Miles</p>
                    <p className='mb-0 profile-company'>Facebook</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_2} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Jenny Wilson</p>
                    <p className='mb-0 profile-company'>eBay</p>
                  </div>
                </div>
              </div>




              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_2} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Ronald</p>
                    <p className='mb-0 profile-company'>Richards</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6  py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_3} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Dianne Russell</p>
                    <p className='mb-0 profile-company'>Mitsubishi</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 py-xl-4 py-lg-4 py-md-4 py-sm-4 p-4'>
                <div className='d-flex align-items-center'>
                  <div>
                    <img src={Profile_1} alt="" className='profile-img' />
                  </div>
                  <div>
                    <p className='mb-0 profile-name' >Devon Lane</p>
                    <p className='mb-0 profile-company'>Facebook</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='row  team-div-padding '>
              <div className='t2_card_div'>
                <div >
                  <div className='team-2-card'>

                    <img src={Profile_1} alt="" className='Team_2_img' />
                    <p className='team_worker_name'>Tom Accor</p>

                    <p className='post_name'>DEVELOPER</p>
                    <p className='post_discrip'>I'm passionate about create elegant theme.</p>
                    <div className='t2-social-div'>
                      <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                    </div>

                  </div>
                </div>

                <div className=' mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4'>
                  <div className='team-2-card'>

                    <img src={Profile_4} alt="" className='Team_2_img' />
                    <p className='team_worker_name'>Tom Accor</p>

                    <p className='post_name'>PROJECT MANAGER</p>
                    <p className='post_discrip'>I'm passionate about create elegant theme.</p>
                    <div className='t2-social-div'>
                      <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                    </div>

                  </div>
                </div>

                <div className=' mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4'>
                  <div className='team-2-card'>

                    <img src={Profile_2} alt="" className='Team_2_img' />
                    <p className='team_worker_name'>Selina Anteree</p>

                    <p className='post_name'>UX DESIGNER</p>
                    <p className='post_discrip'>I'm passionate about create elegant theme.</p>
                    <div className='t2-social-div'>
                      <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                    </div>

                  </div>
                </div>

                <div className=' mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4'>
                  <div className='team-2-card'>

                    <img src={Profile_3} alt="" className='Team_2_img' />
                    <p className='team_worker_name'>Andree Buie</p>

                    <p className='post_name'>GRAPHIC DESIGNER</p>
                    <p className='post_discrip'>I'm passionate about create elegant theme.</p>
                    <div className='t2-social-div'>
                      <TfiTwitter className='t2_twitter' /> <LiaFacebook className='t2_facebook' /> <BsDribbble className='t2_dribbble' />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Team