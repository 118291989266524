import React, { useState } from 'react'
import product2_2 from "../../Img/Black_chair_sofa.webp"
import product4_4 from "../../Img/orange_chair.jpg"
import product01_01 from "../../Img/whitechair.jpg"
import product02_02 from "../../Img/black_chair_2.jpg"
import product03_03 from "../../Img/sofa_chair.jpg"
import product04_04 from "../../Img/soft_dark_chair.jpg"
import { MdClose } from "react-icons/md"
import { useNavigate } from 'react-router-dom'



function Shoppingcart() {
    const navigate = useNavigate()
    const [count, setCount] = useState(0)
    const [count2, setCount2] = useState(0)
    const [count3, setCount3] = useState(0)
    const [count4, setCount4] = useState(0)
    const [count5, setCount5] = useState(0)
    const [count6, setCount6] = useState(0)

    if (count < 1) {
        setCount(1)
    }

    if (count2 < 1) {
        setCount2(1)
    }
    if (count3 < 1) {
        setCount3(1)
    }
    if (count4 < 1) {
        setCount4(1)
    }
    if (count5 < 1) {
        setCount5(1)
    }
    if (count6 < 1) {
        setCount6(1)
    }
    return (
        <>

            <div className='container-fluid mode-div-2 pading-div pading-div'>
                <div className='custom-container'>
                    <div className='container'>
                        <div className='Product-div py-4'>
                            <h1 className='mb-0'>Shopping Cart</h1>
                        </div>
                        <div className='d-xl-block d-lg-block d-none'>
                            <div className='row cart-border' >

                                <div className='col-xl-4 col-lg-4 '>
                                    <p className='Shoping-heading'>Product</p>

                                    <div className='product-img_and_name-div'>
                                        <img src={product2_2} alt="" className='cart-product-img' />
                                        <p className='cart-product-name mb-0'>Fullset Black Chair & Sofa</p>
                                    </div>
                                </div>
                                <div className='col-xl-2  col-lg-2  '>
                                    <p className='Shoping-heading'>Quantity</p>

                                    <div className='  cart-product-button '>
                                        <button className='nagative-btn' onClick={() => setCount(count - 1)}>-</button> {count} <button className='plus-btn' onClick={() => setCount(count + 1)}>+</button>
                                    </div>

                                </div>
                                <div className='col-xl-3 col-lg-3 '>
                                    <p className='Shoping-heading'>Color</p>
                                    <div className='mt-5'>
                                        <select name="Black" id="" defaultValue={'Black'} className='form-select'>
                                            <option value="Black"  > Black</option>
                                            <option value="Blue"> Blue</option>
                                            <option value="White"> White</option>
                                            <option value="Red"> Red</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-3  col-lg-3 '>
                                    <p className='Shoping-heading'>Price</p>

                                    <div className='cart-price-div mt-5'>
                                        <h2 className='cart-price'>$999</h2> <MdClose className='cart-close' />
                                    </div>

                                </div>

                            </div>


                            <div className='row cart-border mt-4' >

                                <div className='col-xl-4 col-lg-4 col-md-8'>


                                    <div className='product-img_and_name-div'>
                                        <img src={product4_4} alt="" className='cart-product-img' />
                                        <p className='cart-product-name mb-0'>Orange Cool Chair</p>
                                    </div>
                                </div>
                                <div className='col-xl-2  col-lg-2 col-md-4'>


                                    <div className='  cart-product-name mt-5'>
                                        <button className='nagative-btn' onClick={() => setCount2(count2 - 1)}>-</button> {count2} <button className='plus-btn' onClick={() => setCount2(count2 + 1)}>+</button>
                                    </div>

                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-4'>

                                    <div className='mt-5'>
                                        <select name="" id="" defaultValue={'Blue'} className='form-select'>
                                            <option value="Orange"  >Orange</option>
                                            <option value="Blue" > Blue</option>
                                            <option value="White"> White</option>
                                            <option value="Red"> Red</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-3  col-lg-3 col-md-4'>


                                    <div className='cart-price-div mt-5'>
                                        <h2 className='cart-price'>$888</h2> <MdClose className='cart-close' />
                                    </div>

                                </div>
                            </div>


                            <div className='row cart-border mt-4' >

                                <div className='col-xl-4 col-lg-4 col-md-8'>


                                    <div className='product-img_and_name-div'>
                                        <img src={product04_04} alt="" className='cart-product-img' />
                                        <p className='cart-product-name mb-0'>Orange Cool Chair</p>
                                    </div>
                                </div>
                                <div className='col-xl-2  col-lg-2 col-md-4'>


                                    <div className='  cart-product-name mt-5'>
                                        <button className='nagative-btn' onClick={() => setCount3(count3 - 1)}>-</button> {count3} <button className='plus-btn' onClick={() => setCount3(count3 + 1)}>+</button>
                                    </div>

                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-4'>

                                    <div className='mt-5'>
                                        <select name="Orange" id="" defaultValue={'White'} className='form-select'>
                                            <option value="Orange"  >Orange</option>
                                            <option value="Blue"> Blue</option>
                                            <option value="White" > White</option>
                                            <option value="Red"> Red</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-3  col-lg-3 col-md-4'>


                                    <div className='cart-price-div mt-5'>
                                        <h2 className='cart-price'>$888</h2> <MdClose className='cart-close' />
                                    </div>

                                </div>
                            </div>


                            <div className='row cart-border mt-4' >

                                <div className='col-xl-4 col-lg-4 col-md-8'>


                                    <div className='product-img_and_name-div'>
                                        <img src={product01_01} alt="" className='cart-product-img' />
                                        <p className='cart-product-name mb-0'>Orange Cool Chair</p>
                                    </div>
                                </div>
                                <div className='col-xl-2  col-lg-2 col-md-4'>


                                    <div className='  cart-product-name mt-5'>
                                        <button className='nagative-btn' onClick={() => setCount4(count4 - 1)}>-</button> {count4} <button className='plus-btn' onClick={() => setCount4(count4 + 1)}>+</button>
                                    </div>

                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-4'>

                                    <div className='mt-5'>
                                        <select name="Orange" id="" defaultValue={'White'} className='form-select'>
                                            <option value="Orange"  >Orange</option>
                                            <option value="Blue"> Blue</option>
                                            <option value="White" > White</option>
                                            <option value="Red"> Red</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-3  col-lg-3 col-md-4'>


                                    <div className='cart-price-div mt-5'>
                                        <h2 className='cart-price'>$888</h2> <MdClose className='cart-close' />
                                    </div>

                                </div>
                            </div>



                            <div className='row cart-border mt-4' >

                                <div className='col-xl-4 col-lg-4 col-md-8'>


                                    <div className='product-img_and_name-div'>
                                        <img src={product02_02} alt="" className='cart-product-img' />
                                        <p className='cart-product-name mb-0'>Orange Cool Chair</p>
                                    </div>
                                </div>
                                <div className='col-xl-2  col-lg-2 col-md-4'>


                                    <div className='  cart-product-name mt-5'>
                                        <button className='nagative-btn' onClick={() => setCount5(count5 - 1)}>-</button> {count5} <button className='plus-btn' onClick={() => setCount5(count5 + 1)}>+</button>
                                    </div>

                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-4'>

                                    <div className='mt-5'>
                                        <select name="Orange" id="" defaultValue={'Orange'} className='form-select'>
                                            <option value="Orange"  >Orange</option>
                                            <option value="Blue"> Blue</option>
                                            <option value="White"> White</option>
                                            <option value="Red"> Red</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-3  col-lg-3 col-md-4'>


                                    <div className='cart-price-div mt-5'>
                                        <h2 className='cart-price'>$888</h2> <MdClose className='cart-close' />
                                    </div>

                                </div>
                            </div>



                            <div className='row cart-border mt-4' >

                                <div className='col-xl-4 col-lg-4 col-md-8'>


                                    <div className='product-img_and_name-div'>
                                        <img src={product03_03} alt="" className='cart-product-img' />
                                        <p className='cart-product-name mb-0'>Orange Cool Chair</p>
                                    </div>
                                </div>
                                <div className='col-xl-2  col-lg-2 col-md-4'>


                                    <div className='  cart-product-name mt-5'>
                                        <button className='nagative-btn' onClick={() => setCount6(count6 - 1)}>-</button> {count6} <button className='plus-btn' onClick={() => setCount6(count6 + 1)}>+</button>
                                    </div>

                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-4'>

                                    <div className='mt-5'>
                                        <select name="Orange" id="" defaultValue={'Red'} className='form-select'>
                                            <option value="Orange"  >Orange</option>
                                            <option value="Blue"> Blue</option>
                                            <option value="White"> White</option>
                                            <option value="Red"> Red</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-3  col-lg-3 col-md-4'>


                                    <div className='cart-price-div mt-5'>
                                        <h2 className='cart-price'>$888</h2> <MdClose className='cart-close' />
                                    </div>

                                </div>
                            </div>

                            <div className='row  py-5'>
                                <div className='total-div'>
                                    <div className=''></div>
                                    <div className=' total-flex-div'>
                                        <h4 className='total mb-0'>Total</h4>
                                        <h2 className='total-price mb-0'>${count * 999 + count2 * 888 + count3 * 888 + count4 * 888 + count5 * 888 + count6 * 888}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-xl-none d-lg-none d-block'>
                            <div className='row cart-border-small'>


                                <div className='col-md-3 col-sm-3 col-4'>
                                    <img src={product2_2} alt=" " className='img-fluid rounded' />
                                </div>


                                <div className='col-md-9 col-sm-9 col-8'>
                                    <p className='cart-product-name-smalls mb-0'>Fullset Black Chair & Sofa</p>
                                    <h2 className='cart-price-smalls mb-0 mt-2'>$ 999</h2>
                                    <div className='  cart-product-button-small'>
                                        <button className='nagative-btn' onClick={() => setCount(count - 1)}>-</button> {count} <button className='plus-btn' onClick={() => setCount(count + 1)}>+</button>
                                        <div className='my-3'>
                                            <select name="Black" id="" defaultValue={'Black'} className='form-select'>
                                                <option value="Black"> Black</option>
                                                <option value="Blue"> Blue</option>
                                                <option value="White"> White</option>
                                                <option value="Red"> Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='row cart-border-small pt-3 '>
                                <div className='col-md-3 col-sm-3 col-4'>
                                    <img src={product4_4} alt=" " className='img-fluid rounded' />
                                </div>

                                <div className='col-md-9 col-sm-9 col-8'>
                                    <p className='cart-product-name-smalls mb-0'>Fullset Black Chair & Sofa</p>
                                    <h2 className='cart-price-smalls mb-0 mt-2'>$ 888</h2>
                                    <div className='  cart-product-button-small'>
                                        <button className='nagative-btn' onClick={() => setCount2(count2 - 1)}>-</button> {count2} <button className='plus-btn' onClick={() => setCount2(count2 + 1)}>+</button>

                                        <div className='my-3'>
                                            <select name="Black" id="" defaultValue={'Black'} className='form-select'>
                                                <option value="Black"  > Black</option>
                                                <option value="Blue"> Blue</option>
                                                <option value="White"> White</option>
                                                <option value="Red"> Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='row cart-border-small pt-3 '>
                                <div className='col-md-3 col-sm-3 col-4'>
                                    <img src={product04_04} alt=" " className='img-fluid rounded' />
                                </div>

                                <div className='col-md-9 col-sm-9 col-8'>
                                    <p className='cart-product-name-smalls mb-0'>Fullset Black Chair & Sofa</p>
                                    <h2 className='cart-price-smalls mb-0 mt-2'>$888</h2>
                                    <div className='  cart-product-button-small'>
                                        <button className='nagative-btn' onClick={() => setCount3(count3 - 1)}>-</button> {count3} <button className='plus-btn' onClick={() => setCount2(count3 + 1)}>+</button>

                                        <div className='my-3'>
                                            <select name="Black" id="" defaultValue={'White'} className='form-select'>
                                                <option value="Black"  > Black</option>
                                                <option value="Blue"> Blue</option>
                                                <option value="White" > White</option>
                                                <option value="Red"> Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row cart-border-small pt-3 '>
                                <div className='col-md-3 col-sm-3 col-4'>
                                    <img src={product01_01} alt=" " className='img-fluid rounded' />
                                </div>

                                <div className='col-md-9 col-sm-9 col-8'>
                                    <p className='cart-product-name-smalls mb-0'>Fullset Black Chair & Sofa</p>
                                    <h2 className='cart-price-smalls mb-0 mt-2'>$888</h2>
                                    <div className='  cart-product-button-small'>
                                        <button className='nagative-btn' onClick={() => setCount4(count4 - 1)}>-</button> {count4} <button className='plus-btn' onClick={() => setCount4(count4 + 1)}>+</button>

                                        <div className='my-3'>
                                            <select name="Black" id="" defaultValue={'White'} className='form-select'>
                                                <option value="Black"  > Black</option>
                                                <option value="Blue"> Blue</option>
                                                <option value="White" > White</option>
                                                <option value="Red"> Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row cart-border-small pt-3 '>
                                <div className='col-md-3 col-sm-3 col-4'>
                                    <img src={product02_02} alt=" " className='img-fluid rounded' />
                                </div>

                                <div className='col-md-9 col-sm-9 col-8'>
                                    <p className='cart-product-name-smalls mb-0'>Fullset Black Chair & Sofa</p>
                                    <h2 className='cart-price-smalls mb-0 mt-2'>$888</h2>
                                    <div className='  cart-product-button-small'>
                                        <button className='nagative-btn' onClick={() => setCount5(count5 - 1)}>-</button> {count5} <button className='plus-btn' onClick={() => setCount5(count5 + 1)}>+</button>

                                        <div className='my-3'>
                                            <select name="Black" id="" defaultValue={'Blue'} className='form-select'>
                                                <option value="Black"  > Black</option>
                                                <option value="Blue" > Blue</option>
                                                <option value="White"> White</option>
                                                <option value="Red"> Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row cart-border-small pt-3 '>
                                <div className='col-md-3 col-sm-3 col-4'>
                                    <img src={product03_03} alt=" " className='img-fluid rounded' />
                                </div>

                                <div className='col-md-9 col-sm-9 col-8'>
                                    <p className='cart-product-name-smalls mb-0'>Fullset Black Chair & Sofa</p>
                                    <h2 className='cart-price-smalls mb-0 mt-2'>$888</h2>
                                    <div className='  cart-product-button-small'>
                                        <button className='nagative-btn' onClick={() => setCount6(count6 - 1)}>-</button> {count6} <button className='plus-btn' onClick={() => setCount6(count6 + 1)}>+</button>

                                        <div className='my-3'>
                                            <select name="Black" id="" defaultValue={'Red'} className='form-select'>
                                                <option value="Black"  > Black</option>
                                                <option value="Blue"> Blue</option>
                                                <option value="White"> White</option>
                                                <option value="Red"> Red</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='btn-div pb-xl-4 pb-lg-4 py-xl-0 py-lg-0 py-md-4 py-sm-4 py-4'>
                            <button className='back-to-shop-btn' onClick={() => navigate("/")}>Back to Homepage</button>
                            <button className='next-to-payment-btn' onClick={() => navigate("/shippingdata")}>Next to Shipping Data</button>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default Shoppingcart